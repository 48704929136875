<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <!-- Header Section -->
    <Header />

    <main class="container">
      <div class="left-container">
        <div v-if="listing" class="listing-details">
          <div v-if="listing" class="listing-info">
            <h2>Your booking has been cancelled!</h2>
            <!-- <h6 class="subheading">{{ listing.location }}</h6> -->

            <div class="event-header">
              <div class="event-header-info">
                <h3>We hope to see you again!</h3>
                <!-- <h6 class="subheading">We hope to see you again!</h6> -->
              </div>
              <!-- <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8jXULUjL7PbaFClpallWqF4YxmYjmYQZdCQ&s"
                alt="User"
                class="user-image"
              /> -->
            </div>
            <button @click="goToListings()" class="back-button">
              Back to Listings >
            </button>
          </div>
        </div>
      </div>

      <div class="right-container">
        <div v-if="listing" class="booking-container">
          <div class="image-wrapper">
            <img
              :src="listing.bannerUrl"
              class="listing-cover"
              alt="Tour Image"
            />
          </div>
          <div class="booking-info">
            <h4 class="tour-title">{{ listing.title }}</h4>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { getFirestore, doc, getDoc } from "firebase/firestore";
import Header from "@/components/organisms/Header.vue";
import { useRoute } from "vue-router";

export default {
  data() {
    return {
      listing: null,
      selectedDate: null,
      guests: 2,
      pricePerGuest: 75,
      promoDiscount: 50,
      listingId: null,
    };
  },
  components: {
    Header,
  },
  methods: {
    fetchListing() {
      const db = getFirestore();
      const listingRef = doc(db, "listings", this.listingId);
      getDoc(listingRef).then((doc) => {
        if (doc.exists()) {
          this.listing = doc.data();
          console.log("Document data:", this.listing);
        } else {
          console.log("No such document!");
        }
      });
    },
    goToListings() {
      this.$router.push("/");
    },
  },
  mounted() {
    const route = useRoute();
    this.listingId = route.query.listingId;
    this.fetchListing();
  },
};
</script>

<style scoped>
/* Container and layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
  color: #333;
}

.web-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
}

.v-calendar .vc-arrows button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
  margin-top: 6rem;
}

.left-container {
  flex: 1.5;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

.left-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.right-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 400px;
}

.logo {
  height: 25px;
  width: 25px;
  margin-right: 1rem;
}

.back-button {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.back-button:hover {
  text-decoration: underline;
}

.header-center {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.header-right {
  display: flex;
}

.subheading {
  color: #7e7e7e;
  text-align: left;
  font-weight: 400;
  font-size: 0.8;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-links a,
.nav-links button {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;
}

.nav-links a:hover,
.nav-links button:hover {
  text-decoration: underline;
}

.main-content {
  padding: 2rem;
}

.listing-details {
  display: grid;
  margin: 20px;
  width: 75%;
  height: auto;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.trip-details {
  margin-top: 1rem;
}

.trip-info {
  margin-bottom: 0.5rem;
}

.trip-info p {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
  text-align: left;
}

.trip-info strong {
  display: block;
  font-weight: bold;
  margin-bottom: 0.2rem;
  text-align: left;
}

.listing-description {
  color: #282828;
  text-align: left;
  font-weight: 200;
  font-size: 0.8;
  line-height: 1.3;
}

.listing-info {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.booking-container {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  height: 90%;
  margin: 20px;
  overflow: hidden;
}

.image-wrapper {
  width: 100%;
  height: 80%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.listing-cover {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.booking-info {
  padding: 10px;
  text-align: left;
}

.location {
  font-size: 0.8rem;
  color: #7e7e7e;
  margin: 0;
}

.tour-title {
  font-size: 1rem;
  color: #333;
  margin: 5px 0 0;
}

.event-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.event-header-info {
  margin-top: 5rem;
  flex-direction: column;
  justify-content: center;
}

.card-container {
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.stepper-container {
  margin-bottom: 20px;
}

.step-item {
  font-size: 1rem;
  color: #333;
}

.content-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.content-box {
  width: 100%;
  height: 100%;
  border: 2px dashed #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn-next {
  background-color: #000;
  color: #fff;
}

.btn-back {
  background-color: #e0e0e0;
  color: #000;
}

h2 {
  font-size: 2rem;
  color: #222;
  text-align: left;
  margin-bottom: 0.5rem;
}

h3 {
  color: #222;
  margin-bottom: 0.5rem;
  text-align: left;
}

button.logout-button {
  border: none;
  border-radius: 50%;
  color: #333;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

button.logout-button:hover {
  background-color: #e5e7eb !important;
}

@media (max-width: 768px) {
  .web-header {
    padding: 1rem;
  }

  .main-content {
    padding: 1rem;
  }
}
</style>
