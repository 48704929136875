<template>
  <div ref="mapContainer" class="map-container"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import pinImage from "@/assets/pin.png";
import { isMobile } from "@/utils/helper";

mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN_1;

export default {
  name: "MapContainer",
  props: ["modelValue", "eventDetails"],

  data: () => ({ map: null, isMobileDevice: isMobile() }),

  mounted() {
    console.log("Initial Event Details:", this.eventDetails);
    const { lng, lat, zoom, bearing, pitch } = {
      // 45.43750986787141, 12.330017483884879
      lat: this.eventDetails.latitude,
      lng: this.eventDetails.longitude,
      // lat: 35.4826,
      // lng: 138.5974,
      bearing: 60,
      pitch: 60,
      zoom: 5,
    };

    const map = new mapboxgl.Map({
      interactive: !this.isMobileDevice,
      container: this.$refs.mapContainer,
      style: process.env.VUE_APP_MAPBOX_STYLE_URL_1,
      center: [lng, lat],
      bearing,
      pitch,
      zoom,
      maxZoom: 12.5,
    });

    const updateLocation = () =>
      this.$emit("update:modelValue", this.getLocation());

    map.on("move", updateLocation);
    map.on("zoom", updateLocation);
    map.on("rotate", updateLocation);
    map.on("pitch", updateLocation);

    this.map = map;

    setTimeout(() => {
      map.easeTo({
        center: [lng, lat],
        zoom: 10,
        bearing: 0,
        pitch: 0,
        curve: 10,
        duration: 5000, // in milliseconds
        easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
      });
    }, 4000); // Wait for 1 second before starting the first zoom

    // Smooth zoom in animation from prefecture to city level
    setTimeout(() => {
      map.easeTo({
        center: [lng, lat],
        zoom: 13,
        bearing: 0,
        pitch: 0,
        duration: 4000, // in milliseconds
        easing: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
      });
    }, 9000);

    setTimeout(() => {
      map.easeTo({
        easing: (t) =>
          t < 0.5 ? 4 * Math.pow(t, 3) : 1 - Math.pow(-2 * t + 2, 3) / 2, // Eases in very slow and eases out very slow
        bearing: 240,
        pitch: 60,
        zoom: 12.5,
        duration: 10000, // in milliseconds
      });
    }, 9000);

    map.once("load", () => {
      const img = document.createElement("img");
      img.src = pinImage;
      img.style.width = "30px";
      img.style.height = "30px";
      // img.style.transform = "translate(-50%, -50%)";
      new mapboxgl.Marker({ element: img, anchor: "bottom", offset: [0, 0] })
        .setLngLat([lng, lat])
        .addTo(map);
    });
  },

  unmounted() {
    this.map.remove();
    this.map = null;
  },

  watch: {
    modelValue(next) {
      const curr = this.getLocation();
      const map = this.map;

      if (curr.lng != next.lng || curr.lat != next.lat)
        map.setCenter({ lng: next.lng, lat: next.lat });
      if (curr.pitch != next.pitch) map.setPitch(next.pitch);
      if (curr.bearing != next.bearing) map.setBearing(next.bearing);
      if (curr.zoom != next.zoom) map.setZoom(next.zoom);
    },
  },

  methods: {
    getLocation() {
      return {
        ...this.map.getCenter(),
        bearing: this.map.getBearing(),
        pitch: this.map.getPitch(),
        zoom: this.map.getZoom(),
      };
    },
  },
};
</script>

<style>
.map-container {
  flex: 1;
  overflow: hidden;
}
</style>
