<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="terms-of-use">
    <h1>Terms of Use</h1>
    <br />
    <br />
    <p><strong>Effective Date:</strong> 1 December 2024</p>
    <br />

    <section>
      <p>
        Welcome to <strong>Skyhost</strong>. Please read on to learn the rules
        and restrictions that govern your use of our website(s), products,
        services, and applications (the “Services”). If you have any questions,
        comments, or concerns regarding these terms or the Services, please
        contact us at <a href="mailto:hello@skyhost.ai">hello@skyhost.ai</a>.
      </p>

      <p>
        These Terms of Use (the “Terms”) are a binding contract between you and
        <strong>Tinker Pte. Ltd.</strong> (“Skyhost,” “we,” and “us”). Your use
        of the Services in any way means that you agree to all of these Terms,
        and these Terms will remain in effect while you use the Services. These
        Terms include the provisions in this document as well as those in the
        Privacy Policy, copyright dispute procedure, and any other relevant
        policies identified from time to time and incorporated herein. Your use
        of or participation in certain Services may also be subject to
        additional policies, rules, and/or conditions (“Additional Terms”),
        which are incorporated herein by reference, and you understand and agree
        that by using or participating in any such Services, you agree to also
        comply with these Additional Terms. Paid services that involve
        establishing an account are only for adults in jurisdictions where such
        accounts are legally permitted.
      </p>

      <p>
        Please read these Terms carefully. They cover important information
        about Services provided to you and any charges, taxes, and fees we bill
        you. These Terms include information about future changes to these
        Terms, automatic renewals, limitations of liability, a class action
        waiver, and resolution of disputes by arbitration instead of in court.
        <strong
          >PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT
          TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING,
          YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</strong
        >
      </p>
    </section>

    <section>
      <h2>ARBITRATION NOTICE AND CLASS ACTION WAIVER</h2>
      <p>
        EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE ARBITRATION
        AGREEMENT SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL
        BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND YOU WAIVE YOUR RIGHT
        TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.
      </p>
    </section>

    <section>
      <h2>Will these Terms ever change?</h2>
      <p>
        We are constantly trying to improve our Services, so these Terms may
        need to change along with our Services. We reserve the right to change
        the Terms at any time, but if we do, we will place a notice on our site
        located at <a href="https://www.SkyHost.ai">www.SkyHost.ai</a>, send you
        an email, and/or notify you by some other means.
      </p>

      <p>
        If you don’t agree with the new Terms, you are free to reject them;
        unfortunately, that means you will no longer be able to use the
        Services. If you use the Services in any way after a change to the Terms
        is effective, that means you agree to all of the changes.
      </p>

      <p>
        Except for changes by us as described here, no other amendment or
        modification of these Terms will be effective unless in writing and
        signed by both you and us.
      </p>
    </section>

    <section>
      <h2>What about my privacy?</h2>
      <p>
        Skyhost takes the privacy of its users very seriously. For the current
        Skyhost Privacy Policy, please click <a href="#privacy-policy">here</a>.
      </p>
    </section>

    <section>
      <h2>Children’s Online Privacy Protection Act</h2>
      <p>
        This Website is not directed toward children under 13 years of age. We
        do not knowingly collect information from children under 13, and we
        request that they do not submit any personally identifiable information
        through the Services.
      </p>
    </section>

    <section>
      <h2>What are the basics of using Skyhost?</h2>
      <p>
        You may be required to sign up for an account, select a password and
        username (“Skyhost User ID”), and provide us with certain information or
        data, such as your contact information. You promise to provide us with
        accurate, complete, and updated registration information about yourself.
        You may not select as your Skyhost User ID a name that you do not have
        the right to use or another person’s name with the intent to impersonate
        that person. You may not transfer your account to anyone else without
        our prior written permission. Account holders must be adults in their
        jurisdictions.
      </p>
    </section>

    <section>
      <h2>
        What if I see something on the Services that infringes my copyright?
      </h2>
      <p>
        In accordance with applicable copyright law, including the Digital
        Millennium Copyright Act (“DMCA”), we’ve adopted the following policy
        toward copyright infringement. We reserve the right to:
      </p>

      <ul>
        <li>
          Remove or disable access to material we believe in good faith to be
          copyrighted material that has been illegally copied or distributed by
          any of our users.
        </li>
        <li>Terminate repeat offenders’ accounts.</li>
      </ul>

      <h3>Procedure for Reporting Copyright Infringements</h3>
      <p>
        If you believe that material or content residing on or accessible
        through the Services infringes your copyright (or the copyright of
        someone you are authorized to act on behalf of), please send a notice of
        copyright infringement containing the following information to Skyhost’s
        Designated Agent:
      </p>

      <ul>
        <li>
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of the copyright that has been allegedly
          infringed.
        </li>
        <li>Identification of works or materials being infringed.</li>
        <li>
          Identification of the material that is claimed to be infringing,
          including information regarding the location of the infringing
          materials that the copyright owner seeks to have removed.
        </li>
        <li>
          Contact information about the notifier, including address, telephone
          number, and email address.
        </li>
        <li>
          A statement that the notifier has a good faith belief that the
          material is not authorized by the copyright owner, its agent, or the
          law.
        </li>
        <li>
          A statement made under penalty of perjury that the information
          provided is accurate and the notifying party is authorized to make the
          complaint on behalf of the copyright owner.
        </li>
      </ul>

      <h3>Designated Agent Contact Information</h3>
      <p>
        <strong>Tinker Pte. Ltd.</strong><br />
        Attn: DMCA Designated Agent<br />
        Email: <a href="mailto:hello@skyhost.ai">hello@skyhost.ai</a>
      </p>
    </section>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Terms",
};
</script>

<style scoped>
.terms-of-use {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h1,
h2,
h3 {
  color: #333;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  color: #000;
  margin: 0;
  font-size: 0.9rem;
  text-align: left;
}

strong {
  font-weight: bold;
  color: #000;
}

section {
  margin-bottom: 20px;
}
</style>
