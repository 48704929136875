<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <!-- Header Section -->
    <!-- <Header /> -->

    <!-- Main Content -->
    <main class="container">
      <div class="left-container">
        <div v-if="listing" class="listing-details">
          <div v-if="listing" class="listing-info">
            <h2>Confirm and Pay</h2>
            <div class="event-header">
              <div class="event-header-info">
                <!-- <p>$</p> -->
                <h1>${{ selectedOption.price }}</h1>
              </div>
            </div>
            <div class="reservation-details">
              <img :src="listing.bannerUrl" class="listing-cover" />
              <div class="reservation-info">
                <h3>{{ listing.title }}</h3>
                <h6>{{ listing.location }}</h6>
                <h6>30 min · Private Live Tour</h6>
              </div>
            </div>

            <div class="trip-details">
              <div class="trip-info">
                <p><strong>Dates</strong></p>
                <p>
                  {{
                    new Date(selectedDate).toLocaleDateString("en-US", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  }}
                </p>
              </div>
              <div class="trip-info">
                <p><strong>Event Time</strong></p>
                <p>{{ selectedSlot.timeString }}</p>
              </div>
              <div class="trip-info">
                <p><strong>Location</strong></p>
                <p>{{ listing.location }}</p>
              </div>
              <div class="trip-info">
                <p><strong>Guests</strong></p>
                <p>Upto {{ selectedOption.guests }} guests</p>
              </div>
              <button class="main-btn" @click="confirmBooking()">
                Checkout with Stripe
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { isMobile } from "@/utils/helper";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { db } from "../plugins/firebase";
import { addDoc, collection } from "firebase/firestore";
import Stripe from "stripe";

export default {
  data() {
    return {
      listing: null,
    };
  },
  methods: {
    async confirmBooking() {
      const stripe = new Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);

      const reservationData = {
        listingId: this.listingId,
        listingUserId: this.listing.creatorUserId,
        createdBy: this.createdBy,
        selectedDate: this.selectedDate,
        selectedSlot: this.selectedSlot,
        selectedOption: this.selectedOption,
        amountPaid: this.totalCost,
        guestCount: this.selectedOption.guests,
        isCancelled: false,
        isConfirmed: false,
        roomCode: null,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      const reservation = await addDoc(
        collection(db, "reservations"),
        reservationData
      );

      const product = await stripe.products.retrieve(
        this.selectedOption.productId
      );

      // Create a checkout session
      const session = await stripe.checkout.sessions.create({
        payment_method_types: ["card"],
        line_items: [
          {
            price_data: {
              currency: "usd",
              product_data: {
                name: product.name,
                images: product.images,
                description: product.description,
              },
              unit_amount: this.totalCost * 100, // Amount in cents
            },
            // price: product.default_price,
            quantity: 1,
          },
        ],
        mode: "payment",
        success_url: `${window.location.origin}/confirmation?reservationId=${reservation.id}`,
        cancel_url: `${window.location.origin}/cancelled?listingId=${this.listingId}`,
      });

      // Redirect to Stripe Checkout
      window.location.href = session.url;
      console.log("session.url", session.url);
      // this.$router.push(`/confirmation?reservationId=${reservation.id}`);
    },
    fetchListing() {
      const db = getFirestore();
      const listingRef = doc(db, "listings", this.listingId);
      getDoc(listingRef).then((doc) => {
        if (doc.exists()) {
          this.listing = doc.data();
          console.log("Document data:", this.listing);
        } else {
          console.log("No such document!");
        }
      });
    },
  },
  mounted() {
    const route = useRoute();
    this.listingId = route.query.listingId;
    this.fetchListing();
  },
  setup() {
    const mainContentClass = computed(() =>
      isMobile() ? "mobile-main-content" : "web-main-content"
    );
    const route = useRoute();

    // Extracting query parameters from the route
    const listingId = route.query.listingId;
    const createdBy = route.query.createdBy;
    const selectedDate = route.query.selectedDate;
    const selectedSlot = JSON.parse(
      decodeURIComponent(route.query.selectedSlot)
    );
    const totalCost = route.query.totalCost;
    const selectedOption = JSON.parse(
      decodeURIComponent(route.query.selectedOption)
    );

    console.log("selectedOption>", selectedOption);

    return {
      listingId,
      createdBy,
      selectedDate,
      selectedSlot,
      totalCost,
      selectedOption,
      mainContentClass,
    };
  },
};
</script>

<style scoped>
/* Container and layout */
.app-container {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  background-color: #fff;
  color: #333;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
}

.left-container {
  flex: 1.5;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  /* max-height: 100vh; */
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

.left-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.subheading {
  color: #7e7e7e;
  text-align: left;
  font-weight: 400;
  font-size: 0.9;
}

.main-content {
  padding: 2rem;
}

.listing-details {
  display: grid;
  /* margin: 20px; */
  width: 85%;
  height: auto;
  align-items: flex-start;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.trip-details {
  margin-top: 1rem;
  align-items: flex-start;
  text-align: left;
}

.trip-info {
  margin-bottom: 0.5rem;
}

.trip-info p {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
  text-align: left;
}

.trip-info strong {
  font-size: 0.8rem;
  color: #000;
  display: block;
  font-weight: bold;
  margin-bottom: 0.2rem;
  text-align: left;
}

.listing-description {
  color: #282828;
  text-align: left;
  font-weight: 200;
  font-size: 0.8;
  line-height: 1.3;
}

.listing-info {
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-top: 5rem;
}

.reservation-details {
  display: flex; /* Use flexbox for layout */
  align-items: flex-start; /* Center items vertically */
  justify-content: flex-start;
  text-align: left; /* Align text to the left */
  flex-wrap: wrap;
  /* margin: 20px 0; Margin for spacing */
}

.listing-cover {
  width: 150px; /* Set a fixed width for the image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners */
  margin-right: 20px; /* Space between image and text */
  margin-bottom: 1rem;
}

.reservation-info {
  display: flex;
  flex-direction: column; /* Stack title and location */
  justify-content: center;
}

.reservation-info h3 {
  font-size: 1.3rem;
  color: #000;
}

.reservation-info h6 {
  font-size: 0.8rem;
  color: #7e7e7e;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.subheading {
  color: #7e7e7e; /* Subheading color */
  font-weight: 400; /* Font weight for subheading */
}

.booking-info {
  width: 100%;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
}

.guest-selector {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-left: 20px;
}

.p-inputnumber {
  width: 100%;
}

.guest-controls {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.booking-summary {
  margin-top: 20px;
}

.booking-summary p {
  margin: 5px 0;
}

.calculation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20px;
}

.calculation p {
  font-size: 0.8rem;
}

.booking-summary h4 {
  margin-top: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.note {
  color: #7e7e7e;
  font-size: 0.8rem;
}

.main-btn {
  margin-top: 20px;
  margin-bottom: 4rem;
  background-color: #000;
  color: white;
  padding: 0.5rem 1rem;
  width: fit-content;
  height: 40px;
  border-radius: 8px;
  border: 0px solid #000;
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;
  cursor: pointer;
}

.event-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.event-header-info {
  margin-top: 5rem;
  display: flex;
  gap: 1px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
}

.event-header-info p {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: 300;
  color: #000;
}

.card-container {
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.stepper-container {
  margin-bottom: 20px;
}

.step-item {
  font-size: 1rem;
  color: #333;
}

.content-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.content-box {
  width: 100%;
  height: 100%;
  border: 2px dashed #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn-next {
  background-color: #000;
  color: #fff;
}

.btn-back {
  background-color: #e0e0e0;
  color: #000;
}

h1 {
  font-size: 3rem;
  color: #222;
  text-align: left;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 2rem;
  color: #222;
  text-align: left;
  margin-bottom: 0.5rem;
}

h3 {
  color: #222;
  margin-bottom: 0.5rem;
}

button.logout-button {
  border: none;
  border-radius: 50%;
  color: #333;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

button.logout-button:hover {
  background-color: #e5e7eb !important;
}

@media (max-width: 768px) {
  .web-header {
    padding: 1rem;
  }

  .main-content {
    padding: 1rem;
  }
}
</style>
