<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <!-- Header Section with Logo, Dashboard, Profile, and Logout -->
    <Header />

    <!-- Sidebar and Main Content -->
    <main :class="mainContentClass">
      <div class="main-header">
        <h1 class="main-title">My Listings</h1>
      </div>

      <!-- <h2 class="header-title">Your Reservations</h2> -->

      <!-- Table -->
      <div class="table-container">
        <div class="table">
          <div class="thead">
            <div class="tr">
              <div class="th">Event Name</div>
              <div class="th">Date</div>
              <div class="th">Time</div>
              <div class="th">Location</div>
            </div>
          </div>
          <div class="tbody">
            <div v-for="flight in flights" :key="flight.id" class="tr">
              <div class="td">{{ flight.title }}</div>
              <div class="td">{{ flight.eventDate }}</div>
              <div class="td">{{ flight.eventTime }}</div>
              <div class="td">{{ flight.location }}</div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- Footer -->
    <!-- <footer class="footer">
      <p>&copy; 2024 Skyhost. All rights reserved.</p>
    </footer> -->
  </div>
</template>

<script>
import {
  getFirestore,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { isMobile } from "@/utils/helper";
import { computed } from "vue";
import Header from "@/components/organisms/Header.vue";
import { auth } from "../plugins/firebase";

export default {
  data() {
    return {
      flights: [],
    };
  },
  components: {
    Header,
  },
  methods: {
    fetchFlights() {
      const userId = auth.currentUser.uid;
      console.log("userId", userId);
      const db = getFirestore();
      const flightsQuery = query(
        collection(db, "listings"),
        orderBy("createdAt", "desc")
      );

      this.unsubscribe = onSnapshot(flightsQuery, (snapshot) => {
        const flights = snapshot.docs
          .filter((doc) => doc.data().creatorUserId === userId)
          .map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });

        this.flights = flights.map((flight) => {
          return {
            ...flight,
            eventDate: flight.createdAt.toDate().toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
            eventTime: flight.createdAt.toDate().toLocaleTimeString(),
          };
        });
      });
    },
  },
  setup() {
    const mainContentClass = computed(() => {
      return isMobile() ? "mobile-main-content" : "web-main-content";
    });

    return {
      mainContentClass,
    };
  },
  mounted() {
    this.fetchFlights();
  },
  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>

<style scoped>
/* Container and layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
}

/* Main Content */
.mobile-main-content {
  margin-top: 20px;
  padding: 20px;
}

.web-main-content {
  margin-top: 6rem;
  padding: 2rem 10rem;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.main-title {
  font-size: 3rem;
  color: #000;
}

.main-btn {
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  height: 45px;
  border-radius: 12px;
  border: 1px solid #3b82f6;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.main-btn:hover {
  background-color: #679ef6;
}

.logout-button {
  background-color: #e5e7eb !important;
  border: none;
  border-radius: 12px;
  color: white;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.logout-button:hover {
  background-color: rgb(235, 235, 235) !important;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
  width: 100%;
}

.table {
  width: 100%; /* Make the table take full width */
  min-width: 600px; /* Set a minimum width for better readability */
}

.thead {
  /* background-color: #2d2d2d; */
  /* border-top-left-radius: 12px;
  border-top-right-radius: 12px; */
}

.th,
.td {
  flex: 1; /* Allow cells to grow equally */
  padding: 1rem; /* Add padding for better spacing */
  text-align: left; /* Align text to the left */
}

.tr {
  display: flex;
  justify-content: column;
}

.th {
  border-right: 0px;
  padding: 1rem;
  text-align: left;
  width: 100%;
  font-size: 14px;
}

.td {
  flex: 1;
  padding: 1rem;
  text-align: left;
  font-size: 14px;
  /* border-bottom: 1px solid #2d2d2d; */
  /* border-right: 1px solid #13141a; */
  /* border-left: 1px solid #2d2d2d; */
}

.th {
  font-weight: bold;
  color: #9ca3af;
}

.td {
  color: #000;
  text-align: left;
}

.tbody .tr:last-child .td:first-child {
  border-bottom-left-radius: 12px;
}

.tbody .tr:last-child .td:last-child {
  border-bottom-right-radius: 12px;
}

.status-completed {
  background-color: #4ade80;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

.status-in-progress {
  background-color: #facc15;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

.status-pending {
  background-color: #3b82f6;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

.status-cancelled {
  background-color: #f87171;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

/* Footer */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  font-size: 0.8rem;
  color: gray;
  text-align: center;
  background-color: #000; /* Ensure the footer has a background color */
}
</style>
