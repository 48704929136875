<!-- src/components/SignUpModal.vue -->
<template>
  <div v-if="isOpen" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop :style="{ width: modalWidth }">
      <h2 class="modal-title">Login</h2>
      <div class="line-divider"></div>
      <form @submit.prevent="handleLogin" class="modal-form">
        <h1 class="modal-heading">Welcome back!</h1>
        <p class="error-message">{{ errorMessage }}</p>
        <div class="form-group">
          <label for="login-email">Email</label>
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="email@example.com"
            required
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder="********"
            required
          />
        </div>
        <button type="submit" class="modal-submit">Login</button>
        <div class="signup-footer">
          <span>Don't have an account?</span>
          <p @click="openSignUpModal">Sign up</p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/helper";

export default {
  name: "LoginModal",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    errorMessage: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    modalWidth() {
      return isMobile() ? "85%" : "35%";
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    openSignUpModal() {
      this.$emit("close");
      this.$emit("openSignUpModal");
    },
    handleLogin() {
      this.onSubmit({
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above all other content */
}

.modal-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-title {
  font-size: 0.9rem;
  font-weight: 600;
  margin: 1rem;
  color: #333;
}

.modal-heading {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.modal-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.error-message {
  height: 20px;
  color: #f97474;
  font-size: 0.9rem;
}

.form-group {
  margin-bottom: 1.2rem;
  text-align: left;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
}

.form-group input:focus {
  border-color: #000; /* Focus color */
}

.modal-submit {
  background-color: #000;
  color: white;
  padding: 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.modal-submit:hover {
  background-color: #181818;
}

.line-divider {
  border: none;
  border-top: 1px solid #e6e4e4;
}

.signup-footer {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.signup-footer span {
  color: #555;
  font-size: 0.8rem;
}

.signup-footer p {
  color: #000;
  font-size: 0.8rem;
  margin-left: 5px;
  cursor: pointer;
  text-decoration: underline;
}
</style>
