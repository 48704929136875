<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <!-- Header Section with Logo, Dashboard, Profile, and Logout -->
    <Header :isDropdownOpen="isDropdownOpen" @toggleDropdown="toggleDropdown" />

    <!-- Sidebar and Main Content -->
    <main :class="mainContentClass">
      <div class="main-header">
        <h1 class="main-title">Your Bookings</h1>
      </div>

      <!-- <h2 class="header-title">Your Reservations</h2> -->

      <!-- Table -->

      <div v-if="reservations.length === 0" class="empty-state">
        <vue-feather type="calendar" size="48" style="color: #aaa" />
        <br />
        <p class="empty-message">
          You don’t have any booking reservations yet.
        </p>
      </div>
      <div class="reservations-container">
        <div
          v-for="reservation in reservations"
          :key="reservation.id"
          class="reservation-card-container"
        >
          <div class="main-content">
            <div class="ticket">
              <div class="ticket-details">
                <img :src="reservation.bannerUrl" :class="listingCoverClass" />
                <div class="detail">
                  <h2 class="flight-title">{{ reservation.eventName }}</h2>
                </div>
                <div class="detail">
                  <span class="label">Location</span>
                  <span class="value">{{ reservation.location }}</span>
                </div>
                <div class="detail">
                  <span class="label">Departure Time</span>
                  <span class="value">{{
                    reservation.selectedSlot.timeString
                  }}</span>
                </div>
                <div class="detail">
                  <span class="label">Departure Date</span>
                  <span class="value">
                    {{
                      new Date(reservation.selectedDate).toLocaleDateString(
                        "en-US",
                        {
                          weekday: "short",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )
                    }}
                  </span>
                </div>
                <div class="ticket-metadata">
                  <div class="detail">
                    <span class="label">Guests</span>
                    <span class="value">Upto {{ reservation.guestCount }}</span>
                  </div>
                </div>
                <div class="divider"></div>
                <div class="detail">
                  <span class="note">
                    {{
                      `Note: Please ensure to arrive 15 minutes prior to departure.`
                    }}
                  </span>
                  <div class="reservation-actions">
                    <button
                      class="main-btn"
                      @click="proceedToGate(reservation.roomCode)"
                      :disabled="!reservation.roomCode"
                    >
                      Proceed to Gate
                    </button>
                  </div>
                </div>
              </div>
              <!-- <div class="note">
                <p>
                  Note: Please ensure to arrive 15 minutes prior to departure.
                </p>
                <div class="reservation-actions">
                  <button
                    class="main-btn"
                    @click="proceedToGate(reservation.roomCode)"
                    :disabled="!reservation.roomCode"
                  >
                    Proceed to Gate
                  </button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>

    <Footer />
  </div>
</template>

<script>
import {
  getFirestore,
  collection,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
  query,
} from "firebase/firestore";
import { isMobile } from "@/utils/helper";
import { computed } from "vue";
import { auth } from "../plugins/firebase";
import Header from "@/components/organisms/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      reservations: [],
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    fetchReservations() {
      const db = getFirestore();
      const reservationsQuery = query(
        collection(db, "reservations"),
        orderBy("createdAt", "desc")
      );

      this.unsubscribe = onSnapshot(reservationsQuery, async (snapshot) => {
        const reservations = snapshot.docs
          .filter((doc) => doc.data().createdBy === auth.currentUser.uid)
          .filter((doc) => doc.data().isConfirmed)
          .map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });

        // Get unique listing IDs
        const uniqueListingIds = [
          ...new Set(reservations.map((reservation) => reservation.listingId)),
        ];

        // Fetch listings details
        const listingsPromises = uniqueListingIds.map(async (listingId) => {
          const listingDoc = await getDoc(doc(db, "listings", listingId));
          return { id: listingDoc.id, ...listingDoc.data() };
        });

        const listings = await Promise.all(listingsPromises);

        this.reservations = reservations
          // .filter((reservation) => reservation.roomCode !== null)
          .map((reservation) => {
            const listingDetails =
              listings.find(
                (listing) => listing.id === reservation.listingId
              ) || {};
            return {
              ...reservation,
              eventName: listingDetails.title || "Unnamed Event",
              location: listingDetails.location,
              bannerUrl: listingDetails.bannerUrl,
              eventDate: reservation.createdAt
                .toDate()
                .toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                }),
              // eventTime: reservation.createdAt.toDate().toLocaleTimeString(),
            };
          });
      });
    },
    proceedToGate(roomCode) {
      console.log("Proceed to gate:", roomCode);
      this.$router.push({
        path: `/${roomCode}`,
      });
    },
  },
  setup() {
    const mainContentClass = computed(() => {
      return isMobile() ? "mobile-main-content" : "web-main-content";
    });

    const listingCoverClass = computed(() => {
      return isMobile() ? "listing-cover-mobile" : "listing-cover-web";
    });

    return {
      mainContentClass,
      listingCoverClass,
    };
  },
  mounted() {
    this.fetchReservations();
  },
  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>

<style scoped>
/* Container and layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
}

/* Main Content */
.mobile-main-content {
  margin-top: 20px;
  padding: 30px;
}

.web-main-content {
  margin-top: 6rem;
  margin-bottom: 20rem;
  padding: 2rem 10rem;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.main-title {
  font-size: 2rem;
  color: #000;
}

.main-btn {
  background-color: #000;
  color: white;
  padding: 0.5rem 1rem;
  height: 45px;
  border-radius: 12px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.main-btn:disabled {
  background-color: #ccc;
}

.main-btn:disabled:hover {
  background-color: #ccc;
}

.main-btn:hover {
  background-color: #679ef6;
}

.logout-button {
  background-color: #e5e7eb !important;
  border: none;
  border-radius: 12px;
  color: white;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.logout-button:hover {
  background-color: rgb(235, 235, 235) !important;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px; /* Adjust height as needed */
  background-color: #f5f5f5; /* Light gray background */
  border-radius: 8px;
  padding: 20px;
}

.empty-message {
  font-size: 1rem;
  color: #7e7e7e;
  text-align: center;
}

.reservations-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reservation-card-container {
  display: flex;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: fit-content;
  /* margin-bottom: 20px; */
}

.main-content {
  /* padding: 0 20px; */
}

.ticket {
  display: flex;
  height: 100%; /* Ensure ticket div matches image height */
}

.ticket-details {
  flex: 1;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ticket-details {
  /* background-color: blueviolet; */
}

.detail {
  display: flex;
  flex-direction: column;
}

.note {
  align-items: flex-end; /* Aligns note content to the bottom */
  flex: 1;
  font-size: 0.9rem;
  color: #7e7e7e;
  display: flex;
  text-wrap: wrap;
}

.flight-title {
  margin-bottom: 0.5rem;
  color: #000;
  text-align: left;
}

.label {
  font-weight: 500;
  color: #919191;
  font-size: 0.9rem;
  margin-right: 5px;
}

.value {
  color: #000;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.divider {
  border-top: 1px solid #e0e0e0;
  margin: 10px 0;
}

.ticket-metadata {
  display: flex;
  justify-content: space-between;
}

.reservation-actions {
  margin-top: 10px;
}

.reservation-card {
  display: flex;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #9ca3af;
  flex-direction: row;
}

.reservation-image {
  flex: 1;
}

.listing-cover-web {
  width: 150px;
  margin-bottom: 1rem;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.listing-cover-mobile {
  width: 150px;
  margin-bottom: 1rem;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.reservation-info {
  flex: 1;
  margin-left: 20px;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
  width: 100%;
}

.table {
  width: 100%; /* Make the table take full width */
  min-width: 600px; /* Set a minimum width for better readability */
}

.thead {
  /* background-color: #2d2d2d; */
  /* border-top-left-radius: 12px;
  border-top-right-radius: 12px; */
}

.th,
.td {
  flex: 1; /* Allow cells to grow equally */
  padding: 1rem; /* Add padding for better spacing */
  text-align: left; /* Align text to the left */
}

.tr {
  display: flex;
  justify-content: column;
}

.th {
  border-right: 0px;
  padding: 1rem;
  text-align: left;
  width: 100%;
  font-size: 14px;
}

.td {
  flex: 1;
  padding: 1rem;
  text-align: left;
  font-size: 14px;
  /* border-bottom: 1px solid #2d2d2d; */
  /* border-right: 1px solid #13141a; */
  /* border-left: 1px solid #2d2d2d; */
}

.th {
  font-weight: bold;
  color: #9ca3af;
}

.td {
  color: #000;
  text-align: left;
}

.tbody .tr:last-child .td:first-child {
  border-bottom-left-radius: 12px;
}

.tbody .tr:last-child .td:last-child {
  border-bottom-right-radius: 12px;
}

.status-completed {
  background-color: #4ade80;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

.status-in-progress {
  background-color: #facc15;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

.status-pending {
  background-color: #3b82f6;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

.status-cancelled {
  background-color: #f87171;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

/* Footer */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  font-size: 0.8rem;
  color: gray;
  text-align: center;
  background-color: #000; /* Ensure the footer has a background color */
}
</style>
