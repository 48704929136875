<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <!-- Header Section -->
    <Header
      hideDropdown
      :isDropdownOpen="isDropdownOpen"
      @toggleDropdown="toggleDropdown"
      @handleLoginSelection="handleLoginSelection"
      @handleSignUpSelection="handleSignUpSelection"
    />

    <!-- Main Content -->
    <main class="container">
      <div class="left-container">
        <div v-if="listing" class="listing-details">
          <div v-if="listing" class="listing-info">
            <h2>{{ listing.title }}</h2>
            <h6 class="subheading">{{ listing.location }}</h6>
            <div v-if="!listing?.isActive" style="margin-top: 0.5rem">
              <Chip
                :class="[
                  'selected-chip',
                  'small-chip',
                  // { 'selected-chip': selectedSlot === slot },
                  // { 'disabled-chip': !slot.isAvailable },
                ]"
                >Coming Soon</Chip
              >
            </div>
            <img :src="listing.bannerUrl" class="listing-cover" />

            <div class="event-header">
              <div class="event-header-info">
                <h3 class="h3-style">Private drone tour hosted by Skyhost</h3>
                <h6 class="subheading">30 min · Online Event</h6>
              </div>
              <!-- <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8jXULUjL7PbaFClpallWqF4YxmYjmYQZdCQ&s"
                alt="User"
                class="user-image"
              /> -->
            </div>

            <hr class="line-divider" />

            <div class="listing-features">
              <div class="feature-item">
                <vue-feather type="monitor" size="18"></vue-feather>
                <div class="feature-item-content">
                  <strong>Private viewing</strong>
                  <p>You'll have the event to yourself and your guest</p>
                </div>
              </div>
              <div class="feature-item">
                <vue-feather type="mic" size="18"></vue-feather>
                <div class="feature-item-content">
                  <strong>Group Conversation</strong>
                  <p>Engage with the pilot and the guests</p>
                </div>
              </div>
              <div class="feature-item">
                <vue-feather type="radio" size="18"></vue-feather>
                <div class="feature-item-content">
                  <strong>Real time</strong>
                  <p>Low latency live streaming</p>
                </div>
              </div>
            </div>

            <h5 class="listing-description">{{ listing.description }}</h5>

            <hr class="line-divider" />

            <div class="map-container">
              <h3 class="h3-style">Where we'll be flying</h3>
              <div class="map-image">
                <MapComponent
                  :latitude="listing.latitude"
                  :longitude="listing.longitude"
                />
              </div>
              <div class="location-coordinates">
                <strong class="location">{{ listing.location }}</strong>
                <div class="coordinates">
                  <p class="latitude">{{ listing.latitude }} N</p>
                  <p class="longitude">{{ listing.longitude }} E</p>
                </div>
              </div>
            </div>

            <hr class="line-divider" />

            <div class="things-container">
              <h3 class="h3-style">Things to know</h3>
              <div class="things-list">
                <div class="thing-item">
                  <vue-feather type="check-circle" size="18" />
                  <span>Check-in: 15 minutes for departure</span>
                </div>
                <div class="thing-item">
                  <vue-feather type="wifi" size="18" />
                  <span>Ensure a stable internet connection</span>
                </div>
                <div class="thing-item">
                  <vue-feather type="user-check" size="18" />
                  <span
                    >Do not ask for personal contacts from pilots or other
                    participants</span
                  >
                </div>
                <div class="thing-item">
                  <vue-feather type="mic" size="18" />
                  <span
                    >Please mute your microphone when you are not speaking</span
                  >
                </div>
                <div class="thing-item">
                  <vue-feather type="x-circle" size="18" />
                  <span>No unauthorized recording</span>
                </div>
                <div class="thing-item">
                  <vue-feather type="user-x" size="18" />
                  <span>No harassment</span>
                </div>
              </div>
            </div>

            <!-- add a section to display please use desktop app to book -->
            <!-- <div class="desktop-app-section">
              <div class="desktop-app-image">
                <vue-feather type="monitor" size="32"></vue-feather>
              </div>
              <h4>To continue booking, access www.skyhost.ai from desktop</h4>
            </div> -->

            <div class="booking-container">
              <div class="booking-header">
                <div class="price-container">
                  <h3>${{ pricePerGuest }}</h3>
                  <span>/ guest</span>
                </div>
              </div>
              <VCalendar
                :disabled-dates="disabledDates"
                :attributes="attrs"
                title-position="left"
                borderless
                transparent
                @dayclick="handleDayClick"
                :selected-dates="selectedDate1"
                :min-date="
                  new Date() > new Date(listing?.startDate)
                    ? new Date()
                    : listing?.startDate
                "
                :max-date="listing?.endDate"
              />
              <div
                class="booking-info"
                style="margin-top: -30px"
                v-if="slots.length"
              >
                <div class="select-time-header">
                  <h4>Select Time</h4>
                  <p>({{ userTimezone }})</p>
                </div>
                <div class="slots-container">
                  <Chip
                    v-for="slot in slots"
                    :key="slot.timeSlot"
                    :label="slot.timeString"
                    :class="[
                      'small-chip',
                      { 'selected-chip': selectedSlot === slot },
                      { 'disabled-chip': !slot.isAvailable },
                    ]"
                    @click="slot.isAvailable ? selectSlot(slot) : null"
                    :style="{
                      textDecoration: !slot.isAvailable
                        ? 'line-through'
                        : 'none',
                    }"
                  />
                </div>
              </div>

              <br />

              <div class="booking-options">
                <h4>Group Size</h4>
                <div class="options-container">
                  <div
                    class="option-item"
                    v-for="method in listing?.bookingOptions"
                    :key="method.value"
                    :class="{
                      'selected-option': selectedOption.value === method.value,
                    }"
                  >
                    <label>
                      <input
                        type="radio"
                        name="deliveryMethod"
                        :value="method"
                        v-model="selectedOption"
                      />
                    </label>
                    <div class="option-details">
                      <strong>{{ method.name.toUpperCase() }}</strong>
                      <p>
                        {{ method.guests === 1 ? "For" : "Upto" }}
                        {{ method.guests }}
                        {{ method.guests === 1 ? "Guest" : " Guests" }}
                      </p>
                      <span>${{ method.price }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="booking-summary">
                <div class="calculation">
                  <p>1 × {{ this.selectedOption.name }} Pass</p>
                  <p>{{ formattedTotalCost }}</p>
                </div>
                <button
                  class="main-btn"
                  @click="bookEvent()"
                  :disabled="!selectedDate || !selectedSlot"
                >
                  Next
                </button>
                <p class="note">Terms and conditions apply</p>
              </div>
              <div class="booking-info"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="right-container"></div>
    </main>

    <!-- <Footer /> -->

    <SignUpModal
      :isOpen="isSignUpModalOpen"
      @close="closeSignUpModal"
      :onSubmit="handleSignUp"
      @openLoginModal="openLoginModal"
      :errorMessage="errorMessage"
    />

    <LoginModal
      :isOpen="isLoginModalOpen"
      @close="closeLoginModal"
      @openSignUpModal="openSignUpModal"
      :onSubmit="handleLoginButton"
      :errorMessage="errorMessage"
    />
  </div>
</template>

<script>
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { isMobile } from "@/utils/helper";
import { computed } from "vue";
import { ref } from "vue";
import Chip from "primevue/chip";
import { auth } from "../plugins/firebase";
import { useSessionStore } from "../stores/session";
import SignUpModal from "@/components/organisms/modal/signUpModal.vue";
import LoginModal from "../components/organisms/modal/loginModal.vue";
import { loginService, userAuth } from "../services/userAuth";
import { amplitude } from "../plugins/amplitude";
import Header from "@/components/organisms/Header.vue";
import MapComponent from "@/components/samplemap.vue";
import { truncateText } from "@/utils/helper";
import { useRoute } from "vue-router";
// import Footer from "@/components/Footer.vue";

export default {
  components: {
    Chip,
    SignUpModal,
    Header,
    MapComponent,
    LoginModal,
    // Footer,
  },
  data() {
    return {
      listing: null,
      selectedDate: null,
      slots: [],
      selectedSlot: null,
      slotsByDate: new Map(),
      promoDiscount: 50,
      errorMessage: "",
      isSignUpModalOpen: false,
      isLoginModalOpen: false,
      selectedOption: null,
      disabledDates: [],
      enableDarkMode: false,
      listingId: null,
      userTimezone: null,
    };
  },
  computed: {
    pricePerGuest() {
      if (this.selectedOption.value === "solo") {
        const pricePerGuest = Math.floor(
          this.selectedOption.price / this.selectedOption.guests
        );
        return pricePerGuest;
      } else if (this.selectedOption.value === "pair") {
        const pricePerGuest = Math.floor(
          this.selectedOption.price / this.selectedOption.guests
        );
        return pricePerGuest;
      } else {
        const pricePerGuest = Math.ceil(
          this.selectedOption.price / this.selectedOption.guests
        );
        return pricePerGuest;
      }
    },
    totalCost() {
      return this.selectedOption.price;
    },
    formattedTotalCost() {
      return `$${this.totalCost}`;
    },
    finalTotal() {
      return Math.max(this.totalCost, 0);
    },
    isUserAuthenticated() {
      const sessionStore = useSessionStore();
      return sessionStore.userId !== null;
    },
  },
  methods: {
    handleLoginSelection() {
      this.openLoginModal();
    },
    handleSignUpSelection() {
      this.openSignUpModal();
    },
    async handleLoginButton(data) {
      amplitude.track("Login Form Submitted", { type: "login" });
      this.errorMessage = "";

      try {
        const user = await loginService.loginUser(data.email, data.password);
        const sessionStore = useSessionStore();
        sessionStore.setUserId(user.uid);
        // this.$router.push("/");
        this.closeLoginModal();
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    setDisabledDates() {
      const disabled = [];
      const startDate = new Date(this.listing.startDate);
      const endDate = new Date(this.listing.endDate);

      for (
        let date = startDate;
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        const dateKey = date.toISOString().split("T")[0];
        if (this.listing.slotsByDate[dateKey]?.length === undefined) {
          disabled.push(new Date(dateKey));
        }
      }
      this.disabledDates = disabled;
    },
    openLoginModal() {
      this.isLoginModalOpen = true;
    },
    closeLoginModal() {
      this.isLoginModalOpen = false;
      this.errorMessage = "";
    },
    openSignUpModal() {
      this.isSignUpModalOpen = true;
    },
    closeSignUpModal() {
      this.isSignUpModalOpen = false;
      this.errorMessage = "";
    },
    getTruncatedDescription(description, maxLength) {
      return truncateText(description, maxLength); // Adjust the character limit as needed
    },
    fetchListing() {
      const db = getFirestore();
      const listingRef = doc(db, "listings", this.listingId);
      getDoc(listingRef).then((doc) => {
        if (doc.exists()) {
          this.listing = doc.data();
          this.selectedOption = this.listing?.bookingOptions[0];
        } else {
          console.log("No such document!");
        }
      });
    },
    convertToUserTimeZone(utcDate, timeZone) {
      // Convert the UTC date to the user's timezone in 12-hour format
      const date = new Date(utcDate); // Convert to Date object
      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone,
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
      return formatter.format(date); // Returns time in "hh:mm AM/PM" format
    },
    convertData(data, timeZone) {
      // Function to convert the time and date to user's timezone
      const convertedDate = this.convertToUserTimeZone(
        data.timeSlot.date,
        timeZone
      );
      return {
        ...data,
        timeSlot: {
          ...data.timeSlot,
          timeString: convertedDate, // Update timeString in user timezone
          date: new Date(data.timeSlot.date).toLocaleString("en-US", {
            timeZone,
          }), // Update date to user timezone
        },
      };
    },
    convertToTargetTimezone(slot, targetTimezone) {
      console.log("Input Slot:", slot, "Target Timezone:", targetTimezone);
      let convertedData = this.convertData(slot, targetTimezone);
      console.log("Original Date with System Timezone:", convertedData);
      slot.timeString = convertedData.timeSlot.timeString;
      return slot;
    },
    handleDayClick(date) {
      console.log("handleDayClick", date);
      this.selectedSlot = null;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const startDate = new Date(this.listing.startDate);
      const endDate = new Date(this.listing.endDate);
      const selectedDate = new Date(date.id);
      const formattedSelectedDate = selectedDate.toISOString().split("T")[0];
      const formatedDisabledDates = this.disabledDates.map(
        (date) => date.toISOString().split("T")[0]
      );
      const isDisabled = formatedDisabledDates.includes(formattedSelectedDate);
      console.log("this.disabledDates", isDisabled);
      if (
        selectedDate < startDate ||
        selectedDate > endDate ||
        selectedDate < today ||
        isDisabled
      ) {
        console.log("Selected date is out of range.");
        return;
      }

      this.selectedDate = date.id;

      const dateKey = selectedDate.toISOString().split("T")[0];
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const listingTimezone = this.listing.timezone;

      this.userTimezone = userTimezone;

      console.log("User Timezone:", userTimezone);
      console.log("Listing Timezone:", listingTimezone);

      const slots = (this.slots = this.listing.slotsByDate[dateKey] || []);
      this.slots = slots.map((slot) =>
        this.convertToTargetTimezone(slot, userTimezone)
      );
      this.updateCalenderAttributes();
    },
    async handleSignUp(data) {
      console.log("Sign up form submitted!");
      this.errorMessage = "";

      amplitude.track("SignUp Form Submitted", {
        type: "signUp",
      });

      try {
        const user = await userAuth.signUpUser(
          data.username,
          data.email,
          data.password,
          data.confirmPassword
        );
        console.log("Signed in as:", user.email);
        const sessionStore = useSessionStore();
        sessionStore.setUserId(user.uid);
        this.$router.push("/");
        this.closeSignUpModal();
      } catch (error) {
        console.log(error);
        // this.errorMessage = error.message;
      }
    },
    selectSlot(slot) {
      this.selectedSlot = slot;
    },
    /**
     * https://stackoverflow.com/questions/66382652/how-to-style-vue-v-calendar-io-date-circle-color-and-text-color
     */
    updateCalenderAttributes() {
      // const today = new Date();
      // today.setHours(0, 0, 0, 0);
      // const dateKeys = Object.keys(this.listing.slotsByDate).filter(
      //   (dateKey) => new Date(dateKey) >= today
      // );

      this.attrs = [
        {
          key: "today",
          dot: {
            color: "#42b983",
          },
          dates: new Date(),
        },
        // {
        //   key: "alldates",
        //   highlight: {
        //     contentStyle: {
        //       fontSize: "8px",
        //     },
        //   },
        //   dates: allDates,
        // },
        // {
        //   key: "eligible",
        //   dot: {
        //     color: "#000",
        //     fillMode: "light",
        //   },
        //   dates: dateKeys,
        // },
        {
          key: "selected",
          highlight: {
            style: {
              backgroundColor: "#000",
            },
            fillMode: "solid",
          },
          dates: this.selectedDate ? [this.selectedDate] : [],
        },
      ];
    },
    bookEvent() {
      console.log("Booking event...");
      console.log(">", this.selectedSlot);
      if (!this.isUserAuthenticated) {
        this.openSignUpModal();
        return;
      }

      const reservation = {
        listingId: this.listingId,
        createdBy: auth.currentUser.uid,
        selectedDate: this.selectedDate,
        selectedSlot: encodeURIComponent(JSON.stringify(this.selectedSlot)),
        guestCount: this.selectedOption.guests,
        selectedOption: encodeURIComponent(JSON.stringify(this.selectedOption)),
        totalCost: this.totalCost,
      };

      this.$router.push({
        path: `/billing`,
        query: reservation,
      });
    },
  },
  watch: {
    listing: {
      handler() {
        this.updateCalenderAttributes();
        this.setDisabledDates();
        this.selectedDate1 = this.listing?.startDate;
      },
      deep: true,
    },
    listingId() {
      this.fetchListing();
    },
  },
  setup() {
    const mainContentClass = computed(() =>
      isMobile() ? "mobile-main-content" : "web-main-content"
    );
    const attrs = ref([
      // {
      //   key: "today",
      //   dot: {
      //     color: "#42b983",
      //   },
      //   dates: new Date(),
      // },
    ]);
    return {
      attrs,
      mainContentClass,
    };
  },
  mounted() {
    // this.fetchListing();
    const route = useRoute();
    this.listingId = route.query.listingId;
  },
};
</script>

<style scoped>
/* Container and layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
  color: #333;
}

.v-calendar .vc-arrows button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.container {
  /* display: flex; */
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
  margin-top: 1rem;
}

.left-container {
  max-width: 100%;
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

.left-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.right-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.subheading {
  color: #7e7e7e;
  text-align: left;
  font-weight: 400;
  font-size: 0.8;
}

.main-content {
  padding: 2rem;
}

.listing-details {
  display: grid;
  margin: 30px;
  height: auto;
  margin-bottom: 400px;
}

.listing-cover {
  width: 100%;
  height: auto;
  max-height: 350px;
  border-radius: 16px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  object-fit: cover;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.listing-features {
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
  text-align: left;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.feature-item i {
  font-size: 16px;
  color: #333;
}

.feature-item-content {
  margin-left: 10px;
}

.feature-item-content p {
  font-size: 12px !important;
}

.feature-item strong {
  font-size: 12px;
  font-weight: bold;
  color: #333;
  text-align: left;
}

.feature-item p {
  margin: 0;
  color: #7e7e7e; /* Adjust text color */
  font-size: 0.9rem; /* Adjust text size */
  text-align: left;
}

.map-container {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.map-container strong {
  font-size: 12px;
  font-weight: bold;
  color: #333;
  text-align: left;
}

.map-image {
  width: 100%;
  margin-bottom: 1rem;
}

.location-coordinates {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Aligns items to the left and right */
  margin-top: 1rem; /* Add some spacing above */
  gap: 0.3rem;
  align-items: flex-start;
}

.location {
  font-size: 1rem; /* Current style for location */
  color: #333; /* Darker color for location */
}

.coordinates {
  display: flex;
  flex-direction: row; /* Stack latitude and longitude vertically */
  align-items: flex-end; /* Align to the right */
  gap: 0.5rem; /* Add some spacing between latitude and longitude */
}

.latitude,
.longitude {
  font-size: 0.7rem; /* Smaller font size for coordinates */
  color: #7e7e7e; /* Lighter color for coordinates */
  font-weight: 200;
}

.things-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.things-list {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.thing-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem; /* Adjust font size as needed */
  color: #333; /* Darker color for text */
}

.thing-item span {
  margin-left: 8px; /* Space between icon and text */
}

.desktop-app-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.desktop-app-image {
  width: 100%;
  height: auto;
  border-radius: 6px;
  margin-bottom: 0.5rem;
}

.listing-description {
  color: #282828;
  text-align: left;
  font-weight: 200;
  font-size: 0.8;
  line-height: 1.3;
  margin-bottom: 1rem;
}

.listing-info {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.calendar-section,
.booking-container {
  /* position: fixed; */
  align-items: center;
  text-align: center;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  height: auto;
  width: 100%;
  flex: 1;
  width: 18rem;
  margin: 0px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333;
}

.booking-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.booking-info {
  width: 100%;
  text-align: left;
  margin-left: 20px;
  margin-top: 15px;
}

.select-time-header {
  display: flex;
  align-items: center;
}

.select-time-header p {
  margin-top: 1px;
  margin-left: 5px;
  font-size: 0.8rem;
}

.price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.price-container span {
  margin-top: 3px;
  font-size: 0.8rem;
}

.slots-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px;
  margin-top: 10px;
  width: 85%;
}

.small-chip {
  font-size: 10px;
  padding: 10px 10px;
  border-radius: 20px;
  border: 1px solid #fff;
  cursor: pointer;
}

.selected-chip {
  background-color: #000;
  color: #fff;
}

.disabled-chip {
  background-color: #fff;
  color: #ccc;
  border: 1px solid #f1f1f1;
  cursor: unset;
}

.guest-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-right: 30px;
  width: auto;
}

.guest-label {
  display: flex;
  flex-direction: column;
  color: #7e7e7e;
  margin-right: 10px;
}

.guest-label strong {
  color: #000;
  font-size: 0.6rem;
}

.guest-label p {
  font-size: 0.8rem;
  color: #7e7e7e;
}

.counter-controls {
  display: flex;
  align-items: center;
}

.counter-button {
  background-color: #fff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.counter-button:hover {
  background-color: #e5e7eb;
}

.counter-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.guest-count {
  margin: 0 5px;
  font-size: 1.2rem;
  font-weight: bold;
}

.p-inputnumber {
  width: 100%;
}

.booking-summary {
  margin-top: 10px;
  text-align: center;
}

.booking-summary p {
  margin: 5px 0;
}

.booking-options {
  text-align: left;
  width: 100%;
  text-align: left;
}

.booking-options h4 {
  margin-left: 20px;
  margin-bottom: 10px;
}

.options-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin: 0 20px;
  justify-content: space-between;
}

.option-item {
  flex: 1; /* Make each option take equal space */
  margin: 0; /* Remove margin to eliminate space between items */
  padding: 5px; /* Add padding for better touch targets */
  border: 1px solid #f2f5f9; /* Optional: Add border for visual separation */
  border-radius: 8px; /* Optional: Rounded corners */
  text-align: left; /* Center text */
  transition: background-color 0.3s; /* Smooth transition for hover */
}

.option-item:hover {
  background-color: #f2f5f9;
}

.option-item.selected-option {
  background-color: #f2f5f9;
}

.option-item span {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #000;
}

.option-item p {
  width: 45px;
  font-size: 0.7rem;
  margin-top: 2px;
  margin-bottom: 5px;
  color: #7e7e7e;
}

.option-item strong {
  font-weight: bold;
  font-size: 0.6rem;
}

.option-item input[type="radio"] {
}

.option-item input[type="radio"]:checked {
  border-color: black; /* Border color when checked */
  background: black; /* Background color when checked */
}

.calculation {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20px;
}

.calculation p {
  font-size: 0.8rem;
}

.total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20px;
}

.total p {
  font-size: 0.8rem;
  font-weight: bold;
}

.booking-summary h4 {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.note {
  color: #7e7e7e;
  font-size: 0.7rem;
  font-weight: 300;
}

.line-divider {
  border: none;
  border-top: 1px solid #e6e4e4;
  margin-top: 15px;
  margin-bottom: 5px;
}

.line-divider1 {
  border: none;
  border-top: 1px solid #e6e4e4;
  margin: 10px 20px;
}

.main-btn {
  background-color: #000;
  color: white;
  padding: 0.5rem 1rem;
  margin: 10px 0;
  width: 85%;
  height: 40px;
  border-radius: 8px;
  border: 0px solid #000;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.main-btn:disabled {
  background-color: #ccc;
}

.event-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.event-header-info {
  flex-direction: column;
  justify-content: center;
}

.card-container {
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.stepper-container {
  margin-bottom: 20px;
}

.step-item {
  font-size: 1rem;
  color: #333;
}

.content-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.content-box {
  width: 100%;
  height: 100%;
  border: 2px dashed #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn-next {
  background-color: #000;
  color: #fff;
}

.btn-back {
  background-color: #e0e0e0;
  color: #000;
}

h2 {
  font-size: 1.5rem;
  color: #222;
  text-align: left;
  margin-bottom: 0.5rem;
}

h3 {
  color: #222;
  align-items: center;
}

.h3-style {
  margin-bottom: 0.5rem;
}

button.logout-button {
  border: none;
  border-radius: 50%;
  color: #333;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

button.logout-button:hover {
  background-color: #e5e7eb !important;
}
</style>
