<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <Header
      hideDropdown
      :enableDarkMode="enableDarkMode && !isUserAuthenticated"
      @handleLoginSelection="handleLoginSelection"
      @handleSignUpSelection="handleSignUpSelection"
    />

    <section v-show="!isUserAuthenticated" class="full-screen-section">
      <h1 class="hero-title">Go where only drones can!</h1>
      <p class="hero-subheading">Aerial adventures with your group</p>
      <br />
      <!-- <div class="book-now-section">
        <button
          class="bokunButton"
          id="bokun_b51d084c_48fc_453c_b606_187a6002c30f"
          data-src="https://widgets.bokun.io/online-sales/55418838-f54d-4a31-99eb-f1c2c721125e/experience/949240?partialView=1"
          data-testid="widget-book-button"
        >
          Book now
        </button>
      </div> -->
      <img
        src="../assets/main-banner.png"
        alt="hero image"
        class="main-banner-image"
      />
      <br />
      <!-- <button class="main-btn">Explore Locations</button> -->
    </section>

    <main class="web-main-content">
      <!-- Grid -->
      <div class="main-content">
        <div class="main-header" v-show="!isUserAuthenticated">
          <h1 class="main-title">Explore the unreachable</h1>
          <p class="main-description">
            Our expert drone pilots reveal the world's hidden wonders
          </p>
        </div>
        <div class="grid-container">
          <div
            class="grid-item"
            :class="{ disabled: !flight.isActive }"
            v-for="flight in flights"
            :key="flight.id"
            @click="navigateToListing(flight.id)"
          >
            <div class="flight-content">
              <div class="image-container">
                <img
                  :src="
                    flight.bannerUrl ||
                    require(`@/assets/placeholder-image-listing.png`)
                  "
                  alt="Flight Image"
                  class="flight-image"
                />
                <div v-if="!flight.isActive" class="chip">Coming soon</div>
              </div>
              <!-- <img
                :src="flight.bannerUrl"
                alt="Flight Image"
                class="flight-image"
              /> -->
              <div class="flight-details">
                <div class="flight-header">
                  <h4>{{ getTruncatedDescription(flight.location, 15) }}</h4>
                  <h2>{{ flight.title }}</h2>
                  <hr class="line-divider" />
                </div>

                <div class="flight-info">
                  <p>{{ getTruncatedDescription(flight.description, 100) }}</p>
                  <!-- <hr class="line-divider" /> -->
                </div>

                <div class="flight-footer">
                  <div class="price-container">
                    <!-- <p>$49</p> -->
                    <span v-show="!flight.isActive">
                      30 min · Private Live Tour
                    </span>
                    <p v-show="flight.isActive">${{ flight.pricePerGuest }}</p>
                    <span v-show="flight.isActive">/ guest</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <section v-show="!isUserAuthenticated" class="number-section">
      <h1 class="main-title">Fly, Experience, Connect</h1>
      <p class="vision-description">
        Join live drone adventures with seamless streaming, group calls, and
        effortless scheduling.
      </p>
      <!-- <br /> -->
      <!-- <div class="number-cards">
        <div class="number-card">
          <vue-feather
            type="navigation-2"
            size="18"
            style="color: black"
          ></vue-feather>
          <h1>63</h1>
          <p>Total Flights</p>
        </div>

        <div class="number-card">
          <vue-feather
            type="clock"
            size="18"
            style="color: black"
          ></vue-feather>
          <h1>31</h1>
          <p>Total Flight Hours</p>
        </div>

        <div class="number-card">
          <vue-feather
            type="users"
            size="18"
            style="color: black"
          ></vue-feather>
          <h1>141</h1>
          <p>Total Passengers Served</p>
        </div>
      </div> -->
    </section>

    <section v-show="!isUserAuthenticated" class="points-section">
      <div class="vision-cards">
        <div class="vision-card">
          <vue-feather
            type="globe"
            size="18"
            style="color: black"
          ></vue-feather>
          <!-- Replace with your icon -->
          <h3>Fly Anywhere</h3>
          <p>
            Explore stunning landscapes and hidden gems from the comfort of your
            home, office or school
          </p>
        </div>

        <div class="vision-card">
          <vue-feather type="mic" size="18" style="color: black"></vue-feather>
          <!-- Replace with your icon -->
          <h3>Live Interaction</h3>
          <p>
            Engage with the pilot and explore in real time with live Q&As and on
            demand navigation
          </p>
        </div>

        <div class="vision-card">
          <vue-feather
            type="radio"
            size="18"
            style="color: black"
          ></vue-feather>
          <!-- Replace with your icon -->
          <h3>No Latency</h3>
          <p>See as it happens without delay for a truly engaging experience</p>
        </div>
      </div>
      <div class="vision-cards">
        <div class="vision-card">
          <vue-feather
            type="award"
            size="18"
            style="color: black"
          ></vue-feather>
          <!-- Replace with your icon -->
          <h3>Full HD Streaming</h3>
          <p>
            Watch every detail in high-definition with stable, uninterrupted
            video streaming
          </p>
        </div>

        <div class="vision-card">
          <vue-feather
            type="users"
            size="18"
            style="color: black"
          ></vue-feather>
          <!-- Replace with your icon -->
          <h3>Group Conference Call</h3>
          <p>
            Chat with your colleagues, friends/family and classmates over a
            thrilling, immersive experience
          </p>
        </div>

        <div class="vision-card">
          <vue-feather
            type="calendar"
            size="18"
            style="color: black"
          ></vue-feather>
          <!-- Replace with your icon -->
          <h3>Easy Scheduling</h3>
          <p>Book your flights at times convenient for your group</p>
        </div>
      </div>
    </section>

    <section v-show="!isUserAuthenticated" class="vision-section">
      <h1 class="main-title">Why SkyHost?</h1>
      <p class="vision-description">
        For bonding, learning or exploring, the choice is yours!
      </p>
      <br />
      <br />

      <div class="vision-cards">
        <div class="vision-card">
          <img src="@/assets/drones-can.jpg" class="card-image" />
          <h3>Access the Inaccessible</h3>
          <p>
            Drones break the barriers of accessibility, bringing the world’s
            hidden wonders to your fingertips
          </p>
        </div>

        <div class="vision-card">
          <img src="@/assets/group-bond.jpg" class="card-image" />
          <h3>Bond with your group</h3>
          <p>
            Now everyone can fly with their friends, colleagues or classmates to
            exotic corners of the world.
          </p>
        </div>

        <div class="vision-card">
          <img src="@/assets/unsustainable.png" class="card-image" />
          <h3>Sustainable Tourism</h3>
          <p>
            Environmental impact of traditional tourism can harm delicate
            ecosystems and cultural heritage
          </p>
        </div>
      </div>
    </section>

    <section v-show="!isUserAuthenticated" class="vision-section">
      <h1 class="main-title">How it works</h1>
      <p class="vision-description">
        We redefine flight exploration with Drones, Livestreaming, and Starlink
      </p>
      <br />
      <br />
      <img src="@/assets/howitworks.png" class="banner-image" />
    </section>

    <section v-show="!isUserAuthenticated" class="vision-section">
      <h1 class="main-title">What People Say</h1>
      <br />
      <br />

      <div class="vision-cards">
        <div class="vision-card">
          <img src="@/assets/sean.png" class="avatar" />
          <h3>Sean</h3>
          <p>
            That was awesome! Latency was very low and resolution was high.
            Looking forward to more adventures! Bravo Zulu!
          </p>
        </div>

        <div class="vision-card">
          <img src="@/assets/caine.png" class="avatar" />
          <h3>Caine</h3>
          <p>Mate it was so cool. Felt really genuine.</p>
        </div>

        <div class="vision-card">
          <img src="@/assets/beechcraft.png" class="avatar" />
          <h3>Toshi</h3>
          <p>
            I felt I am there with you. Nice to look around the sky together.
          </p>
        </div>
      </div>
    </section>

    <section v-show="!isUserAuthenticated" class="subscribe-section">
      <Subscribe />
    </section>

    <Footer />

    <SignUpModal
      :isOpen="isSignUpModalOpen"
      @close="closeSignUpModal"
      :onSubmit="handleSignUp"
      @openLoginModal="openLoginModal"
      :errorMessage="errorMessage"
    />

    <LoginModal
      :isOpen="isLoginModalOpen"
      @close="closeLoginModal"
      @openSignUpModal="openSignUpModal"
      :onSubmit="handleLoginButton"
      :errorMessage="errorMessage"
    />
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import { signOut } from "firebase/auth";
import { useSessionStore } from "@/stores/session";
import {
  getFirestore,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { amplitude } from "../plugins/amplitude";
import { loginService, userAuth } from "../services/userAuth";
import { truncateText } from "@/utils/helper";
import Footer from "@/components/Footer.vue";
import SignUpModal from "@/components/organisms/modal/signUpModal.vue";
import Header from "@/components/organisms/Header.vue";
import LoginModal from "../components/organisms/modal/loginModal.vue";
import Subscribe from "@/components/organisms/Subscribe.vue";

export default {
  data() {
    return {
      flights: [],
      currentMarker: null,
      errorMessage: "",
      isDropdownOpen: false,
      isLoginModalOpen: false,
      isSignUpModalOpen: false,
      loginEmail: "",
      loginPassword: "",
      enableDarkMode: true,
    };
  },
  components: {
    Header,
    Footer,
    SignUpModal,
    LoginModal,
    Subscribe,
  },
  computed: {
    isUserAuthenticated() {
      const sessionStore = useSessionStore();
      return sessionStore.userId !== null;
    },
  },
  methods: {
    getTruncatedDescription(description, maxLength) {
      return truncateText(description, maxLength); // Adjust the character limit as needed
    },
    handleLoginSelection() {
      this.openLoginModal();
    },
    handleSignUpSelection() {
      this.openSignUpModal();
    },
    handleFlightLogsSelection() {
      this.$router.push("/flight-logs");
    },
    openLoginModal() {
      this.isLoginModalOpen = true;
    },
    closeLoginModal() {
      this.isLoginModalOpen = false;
      this.errorMessage = "";
    },
    openSignUpModal() {
      this.isSignUpModalOpen = true;
    },
    closeSignUpModal() {
      this.isSignUpModalOpen = false;
      this.errorMessage = "";
    },
    navigateToListing(listingId) {
      // this.$router.push(`/listing/${listingId}`);
      this.$router.push({
        path: `/listing`,
        query: { listingId: listingId },
      });
    },
    async handleLoginButton(data) {
      amplitude.track("Login Form Submitted", { type: "login" });
      this.errorMessage = "";

      try {
        const user = await loginService.loginUser(data.email, data.password);
        const sessionStore = useSessionStore();
        sessionStore.setUserId(user.uid);
        this.$router.push("/");
        this.closeLoginModal();
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async handleSignUp(data) {
      console.log("Sign up form submitted!");
      this.errorMessage = "";

      amplitude.track("SignUp Form Submitted", {
        type: "signUp",
      });

      try {
        const user = await userAuth.signUpUser(
          data.username,
          data.email,
          data.password,
          data.confirmPassword
        );
        const sessionStore = useSessionStore();
        sessionStore.setUserId(user.uid);
        this.$router.push("/");
        this.closeSignUpModal();
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    fetchFlights() {
      const db = getFirestore();
      const flightsQuery = query(
        collection(db, "listings"),
        orderBy("createdAt", "desc")
      );

      this.unsubscribe = onSnapshot(flightsQuery, (snapshot) => {
        const flights = snapshot.docs
          // .filter((doc) => doc.data().creatorUserId === auth.currentUser.uid)
          .map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });

        this.flights = flights.map((flight) => {
          return {
            ...flight,
            pricePerGuest: 49,
            eventDate: flight.createdAt.toDate().toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            }),
            eventTime: flight.createdAt.toDate().toLocaleTimeString(),
            endDate: new Date(flight.endDate).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
            }),
          };
        });
      });
    },
    logout() {
      signOut(auth)
        .then(() => {
          const sessionStore = useSessionStore();
          sessionStore.clearUser();
          this.$router.push("/");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
  },
  mounted() {
    this.fetchFlights();
    // Dynamically load the Bokun script
    // const script = document.createElement("script");
    // script.src =
    //   "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=55418838-f54d-4a31-99eb-f1c2c721125e";
    // script.async = true;
    // document.body.appendChild(script);
  },
  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

#bokun_b51d084c_48fc_453c_b606_187a6002c30f {
  display: inline-block;
  padding: 10px 20px;
  background: #408c3d;
  border-radius: 5px;
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease;
}
#bokun_b51d084c_48fc_453c_b606_187a6002c30f:hover {
  background: #285726;
}
#bokun_b51d084c_48fc_453c_b606_187a6002c30f:active {
  background: #30682e;
}

.line-divider {
  border: none;
  border-top: 1px solid #e6e4e4;
}

/* Main Content */
.mobile-main-content {
  margin-top: 20px;
  padding: 20px;
}

.web-main-content {
  flex: 1;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
}

.main-content {
  flex-direction: column;
}

.full-screen-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  padding: 20px;
  text-align: center;
  background-color: #000;
}

.hero-title {
  margin-top: 5rem;
  font-size: 3rem;
  font-weight: 100;
  padding: 0 2rem;
}

.hero-subheading {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 100;
  color: #bebebe;
  /* color: #000; */
}

.listing-section,
.experiences-section,
.gift-section,
.host-section {
  background-color: #f8f9fa;
}

.number-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
  text-align: center; /* Center align text */
  padding: 1rem 1rem;
}

.number-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.number-card {
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  width: 250px;
  align-items: center;
  text-align: center;
}

.number-card h1 {
  font-size: 3rem;
  color: #222;
  text-align: center;
  margin-bottom: 0.5rem;
}

.number-card p {
  font-size: 1rem;
  color: #7e7e7e;
  text-align: center;
}

.subscribe-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 10rem 2rem;
}

.problem-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 10rem 1rem;
}

.problem-cards {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}

.problem-image {
  flex: 1;
  width: 100%;
}

.problem-card {
  flex: 1;
  width: 100%;
  padding: 0 1rem;
}

.problem-card h2 {
  margin-top: 1rem;
  font-size: 2rem;
  color: #222;
  text-align: left;
}

.problem-card h3 {
  font-size: 1.5rem;
  color: #222;
  text-align: left;
}

.problem-card p {
  font-size: 1rem;
  color: #7e7e7e;
  text-align: left;
}

.problem-card span {
  color: #000;
}

.problem-card span:hover {
  text-decoration: underline;
}

.learn-more {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  display: flex;
  cursor: pointer;
}

.points-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
  text-align: center; /* Center align text */
  margin-bottom: 3rem;
}

.vision-section {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align items horizontally */
  text-align: center; /* Center align text */
  padding: 5rem 0rem;
}

.vision-title {
  font-size: 3rem;
  margin-bottom: 20px;
  text-align: left;
}

.vision-description {
  font-size: 1.2rem;
  padding: 1rem 2rem; /* Add horizontal padding for better spacing */
  color: #7e7e7e; /* Change text color to white for contrast */
  width: 100%; /* Width of the description box */
}

.vision-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.vision-card {
  /* background: #f8f7f7; */
  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  width: 350px;
  align-items: left;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.vision-card h2 {
  font-size: 2rem;
  color: #222;
  text-align: left;
  margin: 0.5rem 0;
}

.vision-card h3 {
  font-size: 1.5rem;
  color: #222;
  text-align: left;
  margin: 0.5rem 0;
  /* font-weight: 500; */
}

.vision-card p {
  font-size: 1rem;
  color: #7e7e7e;
  text-align: left;
}

.vision-card span {
  color: #000;
}

.vision-card span:hover {
  text-decoration: underline;
}

.learn-more {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  display: flex;
  cursor: pointer;
}

.card-image {
  width: 100%;
  height: 380px;
  object-fit: cover;
  border-radius: 8px; /* Rounded top corners */
}

.card-btn {
  background-color: #3b82f6; /* Button color */
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.card-btn:hover {
  background-color: #679ef6; /* Lighter button color on hover */
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr); /* Two columns */
  gap: 20px; /* Space between grid items */
  padding: 3rem 2rem;
  margin-bottom: 5rem;
  width: 100%;
}

.grid-item {
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Prevent overflow */
  cursor: pointer;
}

.grid-item:hover {
}

.grid-item.disabled {
  opacity: 0.7;
}

.flight-content {
  display: flex; /* Use flexbox for layout */
  max-height: 250px;
  flex-direction: row;
}

.image-container {
  position: relative; /* Position relative for absolute positioning of the chip */
}

.flight-image {
  width: 170px;
  height: 250px;
  border-radius: 8px;
  object-fit: cover;
}

.chip {
  position: absolute; /* Position the chip absolutely */
  top: 10px; /* Distance from the top */
  right: 10px; /* Distance from the right */
  background-color: #fff; /* Background color of the chip */
  color: #000; /* Text color */
  padding: 5px 10px; /* Padding for the chip */
  border-radius: 16px; /* Rounded corners for the chip */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: shadow for depth */
  font-size: 0.8rem; /* Font size */
  font-weight: 500; /* Bold text */
}

.flight-details {
  flex: 1;
  padding: 0 10px; /* Padding for text content */
  display: flex;
  justify-content: space-between;
  flex-direction: column; /* Stack text vertically */
}

.flight-details h4 {
  font-size: 0.8rem; /* Increase font size for location */
  color: #666;
  margin-bottom: 5px;
}

.flight-details h2 {
  font-size: 1.5rem; /* Increase font size for title */
  color: #333;
}

.flight-details p {
  font-size: 0.8rem;
  color: #666;
  /* margin-bottom: 10px; */
}

.flight-header {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.flight-header h2 {
  margin-bottom: 0.5rem;
}

.flight-info {
  display: flex;
  flex: 2.5;
  flex-direction: column;
  justify-content: space-between;
}

.flight-footer {
  display: flex;
  flex: 0.5;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.flight-footer p {
  font-size: 1rem;
  color: #000 !important;
}

.flight-footer span {
  font-size: 0.8rem;
  color: #949494;
}

.price-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.main-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 4rem;
  padding: 0rem 1rem;
}

.main-header h2 {
  color: #000;
}

.main-title {
  font-size: 2rem;
  color: #000;
  text-align: center;
}

.main-description {
  font-size: 1.2rem;
  color: #7e7e7e;
  text-align: center;
  padding: 0 2rem;
}

.main-btn {
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 80px;
  height: 50px;
  border: 1px solid #e5e7eb !important;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px;
  outline: none;
  transition: background-color 0.3s ease;
}

.banner-image {
  width: 85%;
  height: 85%;
  border-radius: 8px;
  object-fit: contain;
}

.main-banner-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.main-btn:hover {
  /* background-color: #679ef6; */
}

.logout-button {
  background-color: #e5e7eb !important;
  border: none;
  border-radius: 12px;
  color: white;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.login-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  height: 50px;
  border: 1px solid #e0e0e0 !important;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px;
  outline: none;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #e0e0e0;
}

.login-button-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  margin-right: 5px;
  position: relative;
}

.logout-button:hover {
  background-color: rgb(235, 235, 235) !important;
}

.dropdown-menu {
  position: absolute;
  top: 75px;
  right: 45px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  width: 250px;
  z-index: 100;
  padding: 10px;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  color: #000;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu ul li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #eee;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above all other content */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 12px;
  width: 350px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.modal-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  flex: 1;
  width: 100%;
  text-align: left;
  flex-direction: column;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
}

.form-group input:focus {
  border-color: #1565c0; /* Focus color */
}

.modal-submit {
  background-color: #000;
  color: white;
  padding: 10px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.modal-submit:hover {
  background-color: #0d47a1;
}
</style>
