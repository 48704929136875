<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="footer-container">
    <div class="footer-content">
      <p>© 2024 Skyhost</p>
      <div class="footer-links">
        <a @click="navigateToTerms">Terms</a>
        <span>·</span>
        <a @click="navigateToPrivacy">Privacy</a>
      </div>
      <div class="footer-social">
        <a href="https://www.instagram.com/_skyhost_/">
          <vue-feather type="instagram" size="18" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  methods: {
    navigateToTerms() {
      this.$router.push("/terms");
    },
    navigateToPrivacy() {
      this.$router.push("/privacy");
    },
  },
};
</script>

<style scoped>
.footer-container {
  display: flex;
  width: 100%;
  justify-content: center; /* Center the footer content */
  padding: 30px 0px;
  border-top: 1px solid #f2f5f9; /* Top border for separation */
}

.footer-content {
  width: 90%;
  display: flex; /* Use flexbox for horizontal layout */
  flex-direction: row; /* Align items in a row */
  align-items: center; /* Center items vertically */
  justify-content: space-between;
  font-size: 0.8rem; /* Font size */
  color: #333; /* Text color */
}

.footer-links {
  width: 8%;
  display: flex; /* Use flexbox for horizontal layout */
  justify-content: space-between;
}

.footer-language-currency,
.footer-social {
  margin: 0 10px; /* Margin for spacing between items */
}

.footer-links a,
.footer-social a {
  color: #000; /* Link color */
  text-decoration: none; /* Remove underline */
}

.footer-links a:hover,
.footer-social a:hover {
  text-decoration: underline; /* Underline on hover */
}
</style>
