<template>
  <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <br />
    <br />
    <p><strong>Effective Date:</strong> 1 December 2024</p>

    <section>
      <p>
        At <strong>Skyhost</strong>, we take your privacy seriously. Please read
        this Privacy Policy to learn how we handle your personal data. By using
        or accessing our Services in any manner, you acknowledge that you accept
        the practices and policies outlined below, and you consent to our
        collection, use, and sharing of your information as described in this
        Privacy Policy.
      </p>
      <p>
        Remember that your use of Skyhost’s Services is at all times subject to
        our Terms of Use, which incorporates this Privacy Policy. Any terms we
        use in this Policy without defining them have the definitions given to
        them in the Terms of Use.
      </p>
    </section>

    <section id="table-of-contents">
      <h2>Privacy Policy Table of Contents</h2>
      <ul>
        <li>
          <a href="#what-this-privacy-policy-covers"
            >What this Privacy Policy Covers</a
          >
        </li>
        <li>
          <a href="#personal-data">Personal Data</a>
          <ul>
            <li>
              <a href="#categories-of-personal-data-we-collect"
                >Categories of Personal Data We Collect</a
              >
            </li>
            <li>
              <a href="#categories-of-sources-of-personal-data"
                >Categories of Sources of Personal Data</a
              >
            </li>
            <li>
              <a
                href="#our-commercial-or-business-purposes-for-collecting-personal-data"
                >Our Commercial or Business Purposes for Collecting Personal
                Data</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a href="#how-we-share-your-personal-data"
            >How We Share Your Personal Data</a
          >
        </li>
        <li>
          <a href="#tracking-tools-advertising-and-opt-out"
            >Tracking Tools, Advertising, and Opt-Out</a
          >
        </li>
        <li>
          <a href="#data-security-and-retention">Data Security and Retention</a>
        </li>
        <li>
          <a href="#personal-data-of-children">Personal Data of Children</a>
        </li>
        <li>
          <a href="#changes-to-this-privacy-policy"
            >Changes to this Privacy Policy</a
          >
        </li>
        <li><a href="#contact-information">Contact Information</a></li>
      </ul>
    </section>

    <section id="what-this-privacy-policy-covers">
      <h2>What this Privacy Policy Covers</h2>
      <p>
        This Privacy Policy covers how we treat Personal Data that we gather
        when you access or use our Services. “Personal Data” means any
        information that identifies or relates to a particular individual and
        includes information referred to as “personally identifiable
        information” or “personal information” under applicable data privacy
        laws, rules, or regulations. This Privacy Policy does not cover the
        practices of companies we don’t own or control or people we don’t
        manage.
      </p>
    </section>

    <section id="personal-data">
      <h2>Personal Data</h2>
      <section id="categories-of-personal-data-we-collect">
        <h3>Categories of Personal Data We Collect</h3>
        <table>
          <thead>
            <tr>
              <th>Category of Personal Data</th>
              <th>Examples of Personal Data We Collect</th>
              <th>
                Categories of Third Parties With Whom We Share this Personal
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Profile or Contact Data</td>
              <td>
                <ul>
                  <li>First and last name</li>
                  <li>Email</li>
                  <li>Mailing address</li>
                  <li>Account credentials</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Service Providers</li>
                  <li>Advertising Partners</li>
                  <li>Business Partners</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Payment Data</td>
              <td>
                <ul>
                  <li>Billing address</li>
                  <li>Transaction history</li>
                  <li>Payment method</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Payment Processors</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Device/IP Data</td>
              <td>
                <ul>
                  <li>IP address</li>
                  <li>Device ID</li>
                  <li>Type of device/browser</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Analytics Providers</li>
                  <li>Service Providers</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <section id="categories-of-sources-of-personal-data">
        <h3>Categories of Sources of Personal Data</h3>
        <p>We collect Personal Data about you from the following sources:</p>
        <ul>
          <li>
            <strong>You:</strong> When you provide data directly, such as
            signing up for an account or completing a purchase.
          </li>
          <li>
            <strong>Automatically:</strong> When we collect data from cookies or
            other tracking technologies.
          </li>
          <li>
            <strong>Third Parties:</strong> Including analytics providers and
            advertising partners.
          </li>
        </ul>
      </section>
    </section>

    <section id="how-we-share-your-personal-data">
      <h2>How We Share Your Personal Data</h2>
      <p>
        We share Personal Data with our service providers, business partners,
        and as required by law. We do not sell your data.
      </p>
    </section>

    <section id="tracking-tools-advertising-and-opt-out">
      <h2>Tracking Tools, Advertising, and Opt-Out</h2>
      <p>
        We use cookies and other tracking tools to improve our services. You may
        opt out of certain tracking by adjusting your browser settings.
      </p>
    </section>

    <section id="data-security-and-retention">
      <h2>Data Security and Retention</h2>
      <p>
        We use industry-standard security measures to protect your data. We
        retain your information as long as necessary to provide services or as
        required by law.
      </p>
    </section>

    <section id="personal-data-of-children">
      <h2>Personal Data of Children</h2>
      <p>
        Our services are not directed toward children under 13. We do not
        knowingly collect data from children under 13.
      </p>
    </section>

    <section id="changes-to-this-privacy-policy">
      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of significant changes via email or through our website.
      </p>
    </section>

    <section id="contact-information">
      <h2>Contact Information</h2>
      <p>If you have questions about this Privacy Policy, contact us at:</p>
      <p>
        <strong>Tinker Pte. Ltd.</strong><br />
        Email: <a href="mailto:hello@skyhost.ai">hello@skyhost.ai</a>
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.privacy-policy {
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h1,
h2,
h3 {
  color: #333;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  color: #000;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

ul {
  margin: 10px 0;
  padding-left: 20px;
}

ul li {
  list-style-type: disc;
  color: #000;
}

p {
  color: #000;
  margin: 0;
  font-size: 0.9rem;
  text-align: left;
}

strong {
  font-weight: bold;
  color: #000;
}

section {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
