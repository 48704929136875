<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="subscribe-info" v-if="!isSubscribed">
    <h1>Don't miss your flight!</h1>
    <p>Be the first to know when new locations are available</p>
    <br />
    <div class="form-group">
      <div class="input-container">
        <input
          type="text"
          id="subscribeEmail"
          v-model="subscribeEmail"
          placeholder="Email Address"
          required
        />
      </div>
      <button
        :disabled="subscribeEmail === ''"
        class="modal-submit"
        @click="handleSubscribe"
      >
        Subscribe
      </button>
    </div>
  </div>

  <div class="subscribe-info" v-else>
    <div class="subscription-success">
      <img src="@/assets/checkmark-icon.png" class="checkmark" />
      <br />
      <h1>Thank you for subscribing!</h1>
    </div>
  </div>
</template>

<script>
import { getFirestore, collection, addDoc } from "firebase/firestore";

export default {
  data() {
    return {
      subscribeEmail: "",
      isSubscribed: false,
    };
  },
  methods: {
    handleSubscribe() {
      this.isSubscribed = true;
      const db = getFirestore();
      const subscribersRef = collection(db, "subscribers");
      const subscribersData = {
        email: this.subscribeEmail,
        subscribedAt: new Date(),
        isSubscribed: true,
        unsubscribedAt: null,
      };
      addDoc(subscribersRef, subscribersData)
        .then(() => {
          console.log("Email subscribed:", this.subscribeEmail);
          this.subscribeEmail = "";
          this.isSubscribed = true;
        })
        .catch((error) => {
          console.error("Error subscribing email:", error);
        });
    },
  },
};
</script>

<style scoped>
.subscribe-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 15rem 2rem;
}

.subscribe-info {
  align-items: left;
}

.subscribe-info h1 {
  font-size: 3rem;
  color: #000;
}

.subscribe-info p {
  color: #7e7e7e;
  margin-top: 1rem;
}

.subscribe-form {
}

.form-group {
  flex: 1;
  width: 100%;
  text-align: left;
  flex-direction: column;
  margin-top: 2rem;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
}

.form-group input:focus {
  border-color: #000; /* Focus color */
}

.modal-submit {
  background-color: #000;
  color: white;
  padding: 10px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.modal-submit:hover {
  background-color: #1d1d1d;
}

.modal-submit:disabled {
  background-color: #ccc;
}

.subscription-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.checkmark {
  width: 75px;
  height: 75px;
}
</style>
