import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { defineStore } from "pinia";

export const useSessionStore = defineStore("session", {
  state: () => ({
    userId: null,
    user: null,
  }),
  actions: {
    setUserId(userId) {
      this.userId = userId;
      this.fetchUserDetails(userId);
    },
    async fetchUserDetails(userId) {
      const db = getFirestore();
      const q = query(collection(db, "users"), where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDetails = querySnapshot.docs[0].data();
        this.user = userDetails;
      } else {
        console.log("No user found with the given userId.");
      }
    },
    clearUser() {
      this.userId = null;
      this.user = null;
    },
  },
});
