<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <!-- Header Section with Logo, Dashboard, Profile, and Logout -->
    <Header />

    <!-- Sidebar and Main Content -->
    <main :class="mainContentClass">
      <div class="main-header">
        <h1 class="main-title">Welcome</h1>
        <div>
          <button class="main-btn" @click="navigateToNewFlight">
            <vue-feather
              style="margin-top: 4px; color: white"
              type="plus"
              size="18"
            />
          </button>
        </div>
      </div>

      <h2 class="header-title">Your Reservations</h2>

      <!-- <VCalendar
        expanded
        title-position="left"
        borderless
        :min-date="new Date()"
        view="weekly"
      /> -->

      <!-- Table -->
      <div class="table-container">
        <div class="table">
          <div class="thead">
            <div class="tr">
              <!-- <div class="th">Listing Id</div> -->
              <div class="th">Date</div>
              <div class="th">Time</div>
              <div class="th">Guest Count</div>
              <div class="th">Status</div>
              <div class="th"></div>
            </div>
          </div>
          <div class="tbody" v-if="reservations.length">
            <div
              v-for="reservation in reservations"
              :key="reservation.id"
              class="tr"
            >
              <!-- <div class="td">{{ reservation.listingId }}</div> -->
              <div class="td">{{ reservation.selectedDate }}</div>
              <div class="td">
                {{ reservation.selectedSlot?.timeString }}
              </div>
              <div class="td">{{ reservation.guestCount }}</div>
              <div class="td">
                {{ reservation.isConfirmed ? "Confirmed" : "Not Confirmed" }}
              </div>
              <div class="td">
                <button
                  class="btn-primary"
                  :disabled="reservation.isConfirmed === false"
                  @click="startBoarding(reservation.id)"
                >
                  Start
                </button>
                <!-- <button
                  class="btn-primary"
                  @click="cancelEvent(reservation.id)"
                >
                  Cancel
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- Footer -->
    <!-- <footer class="footer">
      <p>&copy; 2024 Skyhost. All rights reserved.</p>
    </footer> -->
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import { signOut } from "firebase/auth";
import { useSessionStore } from "@/stores/session";
import {
  getFirestore,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { isMobile } from "@/utils/helper";
import { computed } from "vue";
import Header from "@/components/organisms/Header.vue";

export default {
  data() {
    return {
      reservations: [],
    };
  },
  components: {
    Header,
  },
  methods: {
    navigateToNewFlight() {
      this.$router.push("/list-an-event");
    },
    startBoarding(reservationId) {
      this.$router.push({
        path: "/new-flight",
        query: {
          reservationId: reservationId,
        },
      });
    },
    fetchReservations() {
      // fetch user data from session store
      // const sessionStore = useSessionStore();
      const userId = auth.currentUser.uid;
      const db = getFirestore();
      const reservationsQuery = query(
        collection(db, "reservations"),
        orderBy("createdAt", "desc")
      );

      this.unsubscribe = onSnapshot(reservationsQuery, (snapshot) => {
        this.reservations = snapshot.docs
          .filter((doc) => doc.data().listingUserId === userId)
          .map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });
      });
    },
    logout() {
      signOut(auth)
        .then(() => {
          const sessionStore = useSessionStore();
          sessionStore.clearUser();
          this.$router.push("/");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
  },
  setup() {
    const mainContentClass = computed(() => {
      return isMobile() ? "mobile-main-content" : "web-main-content";
    });

    return {
      mainContentClass,
    };
  },
  mounted() {
    this.fetchReservations();
  },
  beforeUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
}

/* Main Content */
.mobile-main-content {
  margin-top: 20px;
  padding: 20px;
}

.web-main-content {
  padding: 8rem 10rem;
}

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.main-title {
  font-size: 3rem;
  color: #000;
}

.main-btn {
  background-color: #3b82f6;
  color: white;
  /* padding: 0.5rem 1rem; */
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  border: 1px solid #3b82f6;
  font-size: 14px;
  /* font-weight: 500; */
  cursor: pointer;
}

.main-btn:hover {
  background-color: #679ef6;
}

.logout-button {
  background-color: #e5e7eb !important;
  border: none;
  border-radius: 12px;
  color: white;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.logout-button:hover {
  background-color: rgb(235, 235, 235) !important;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
  width: 100%;
}

.table {
  width: 100%; /* Make the table take full width */
  min-width: 600px; /* Set a minimum width for better readability */
}

.thead {
  /* background-color: #2d2d2d; */
  /* border-top-left-radius: 12px;
  border-top-right-radius: 12px; */
}

.th,
.td {
  flex: 1; /* Allow cells to grow equally */
  padding: 1rem; /* Add padding for better spacing */
  text-align: left; /* Align text to the left */
}

.tr {
  display: flex;
  justify-content: column;
}

.th {
  border-right: 0px;
  padding: 1rem;
  text-align: left;
  width: 100%;
  font-size: 14px;
}

.td {
  flex: 1;
  padding: 1rem;
  text-align: left;
  font-size: 14px;
  /* border-bottom: 1px solid #2d2d2d; */
  /* border-right: 1px solid #13141a; */
  /* border-left: 1px solid #2d2d2d; */
}

.th {
  font-weight: bold;
  color: #9ca3af;
}

.td {
  color: #000;
  text-align: left;
}

.tbody .tr:last-child .td:first-child {
  border-bottom-left-radius: 12px;
}

.tbody .tr:last-child .td:last-child {
  border-bottom-right-radius: 12px;
}

.btn-primary {
  background-color: #000;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.btn-primary:hover {
  background-color: #222222;
}

.btn-primary:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.status-completed {
  background-color: #4ade80;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

.status-in-progress {
  background-color: #facc15;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

.status-pending {
  background-color: #3b82f6;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

.status-cancelled {
  background-color: #f87171;
  padding: 0.5rem;
  border-radius: 0.5rem;
  color: white;
}

/* Footer */
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  font-size: 0.8rem;
  color: gray;
  text-align: center;
  background-color: #000; /* Ensure the footer has a background color */
}
</style>
