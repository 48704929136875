import * as amplitude from "@amplitude/analytics-browser";

const initializeAmplitude = () => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return;
  }
  amplitude.init(process.env.VUE_APP_AMPLITUDE_API_KEY);
};

export { amplitude, initializeAmplitude };
