<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <!-- Header Section -->
    <Header />

    <!-- Main Content -->
    <main class="container">
      <div class="left-container">
        <div v-if="listing" class="listing-details">
          <div v-if="listing" :class="listingInfoClass">
            <h1>It's time to fly</h1>
            <div class="event-header">
              <div class="event-header-info">
                <h2>Your private drone tour is booked!</h2>
              </div>
            </div>

            <div class="event-header">
              <div class="reservation-details">
                <img :src="listing.bannerUrl" class="listing-cover-small" />
                <div class="reservation-info">
                  <br v-show="isMobile" />
                  <h3>{{ listing.title }}</h3>
                  <h6>{{ listing.location }}</h6>
                  <h6>30 min · Private Live Tour</h6>
                </div>
              </div>
            </div>

            <div class="trip-details">
              <div class="trip-info">
                <p><strong>Dates</strong></p>
                <p>
                  {{
                    new Date(this.reservation.selectedDate).toLocaleDateString(
                      "en-US",
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      }
                    )
                  }}
                </p>
              </div>
              <div class="trip-info">
                <p><strong>Event Time</strong></p>
                <p>{{ this.reservation.selectedSlot.timeString }}</p>
              </div>
              <div class="trip-info">
                <p><strong>Location</strong></p>
                <p>{{ this.listing.location }}</p>
              </div>
              <div class="trip-info">
                <p><strong>Guests</strong></p>
                <p>Upto {{ this.reservation.guestCount }} guests</p>
              </div>
            </div>

            <button class="main-btn" @click="goToBooking()">
              Go to Bookings
            </button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { isMobile } from "@/utils/helper";
import Header from "@/components/organisms/Header.vue";
import { computed } from "vue";
import { ref } from "vue";
import { useSessionStore } from "../stores/session";
import { useRoute } from "vue-router";

export default {
  data() {
    return {
      reservationId: null,
      reservation: null,
      listingId: null,
      listing: null,
      isMobile: false,
    };
  },
  components: {
    Header,
  },
  methods: {
    goToBooking() {
      this.$router.push({
        path: `/bookings`,
      });
    },
    fetchReservations() {
      const db = getFirestore();
      const reservationRef = doc(db, "reservations", this.reservationId);
      getDoc(reservationRef).then((doc) => {
        if (doc.exists()) {
          this.reservation = doc.data();
          this.listingId = this.reservation.listingId;
        } else {
          console.log("No such document!");
        }
      });
    },
    fetchListing() {
      const db = getFirestore();
      const listingRef = doc(db, "listings", this.listingId);
      getDoc(listingRef).then(async (doc) => {
        if (doc.exists()) {
          this.listing = doc.data();
        } else {
          console.log("No such document!");
        }
      });
    },
    async sendEmail(emailData) {
      return fetch("https://skyhost.ai/send-mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Email sent successfully");
          return data;
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          throw error;
        });
    },
    async sendConfirmationEmail() {
      if (!this.reservation) {
        return;
      }

      if (!this.listing) {
        console.error("Listing data is not available.");
        return; // Exit if listing is not available
      }

      const sessionStore = useSessionStore();
      const userEmail = sessionStore.user?.email;
      const reservationIdShort = this.reservationId
        .substring(0, 6)
        .toUpperCase();

      const eventDate = new Date(
        this.reservation.selectedDate
      ).toLocaleDateString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      const emailData = {
        reservationId: reservationIdShort,
        email: userEmail,
        subject: `Reservation confirmed for ${this.listing.title} on ${eventDate} at ${this.reservation.selectedSlot.timeString}`,
        receiverName: this.reservation.name,
        hostName: "Skyhost",
        bannerUrl: this.listing.bannerUrl,
        eventName: this.listing.title,
        location: this.listing.location,
        eventDate: eventDate,
        eventTime: this.reservation.selectedSlot.timeString,
        guestCount: this.reservation.guestCount,
        amountPaid: this.reservation.amountPaid,
      };

      // Call the sendEmail function
      try {
        await this.sendEmail(emailData);
      } catch (error) {
        console.error("Failed to send email:", error);
      }
    },
    updateReservationStatus() {
      const db = getFirestore();
      const reservationRef = doc(db, "reservations", this.reservationId);
      updateDoc(
        reservationRef,
        {
          isConfirmed: true,
        },
        {
          merge: true,
        }
      );
    },
    updateSlotMetadata() {
      const dateKey = new Date(this.reservation.selectedDate)
        .toISOString()
        .split("T")[0];
      const slots = this.listing.slotsByDate[dateKey] || [];

      slots.forEach((slot) => {
        if (
          slot.timeSlot.timeString ===
          this.reservation.selectedSlot.timeSlot.timeString
        ) {
          slot.isAvailable = false;
        }
      });

      // Update the listing in Firestore
      const db = getFirestore();
      const listingRef = doc(db, "listings", this.listingId);
      updateDoc(
        listingRef,
        { slotsByDate: this.listing.slotsByDate },
        { merge: true }
      )
        .then(() => {
          console.log("Slot metadata updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating slot metadata:", error);
        });
    },
  },
  watch: {
    listingId() {
      this.fetchListing();
    },
    listing() {
      this.updateSlotMetadata();
      this.updateReservationStatus();

      setTimeout(() => {
        this.sendConfirmationEmail();
      }, 1000);
    },
  },
  setup() {
    const mainContentClass = computed(() =>
      isMobile() ? "mobile-main-content" : "web-main-content"
    );
    const headerClass = computed(() =>
      isMobile() ? "mobile-header" : "web-header"
    );
    const listingInfoClass = computed(() => {
      return isMobile() ? "mobile-listing-info" : "web-listing-info";
    });

    const attrs = ref([
      {
        key: "today",
        // highlight: "black",
        dot: {
          color: "#42b983",
        },
        dates: new Date(),
      },
    ]);
    return {
      attrs,
      mainContentClass,
      headerClass,
      listingInfoClass,
    };
  },
  mounted() {
    const route = useRoute();
    this.reservationId = route.query.reservationId;
    this.fetchReservations();
    this.isMobile = isMobile();
  },
};
</script>

<style scoped>
/* Container and layout */
.app-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #333;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
}

.left-container {
  flex: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-height: 90vh; */
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

.left-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.right-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.logo {
  height: 25px;
  width: 25px;
  margin-right: 1rem;
}

.header-center {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.header-right {
  display: flex;
}

.subheading {
  color: #7e7e7e;
  text-align: left;
  font-weight: 400;
  font-size: 0.8;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-links a,
.nav-links button {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;
}

.nav-links a:hover,
.nav-links button:hover {
  text-decoration: underline;
}

.main-content {
  padding: 2rem;
}

.listing-details {
  display: grid;
  /* margin: 20px; */
  width: 75%;
  height: auto;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.trip-details {
  margin-top: 1rem;
}

.trip-info {
  margin-bottom: 0.5rem;
}

.trip-info p {
  margin: 0;
  font-size: 0.9rem;
  color: #333;
  text-align: left;
}

.trip-info strong {
  display: block;
  font-weight: bold;
  margin-bottom: 0.2rem;
  text-align: left;
  color: #000;
}

.main-btn {
  margin-top: 20px;
  background-color: #000;
  color: white;
  padding: 0.5rem 1rem;
  /* width: 35%; */
  height: 40px;
  border-radius: 8px;
  border: 0px solid #000;
  font-size: 14px;
  font-weight: 500;
  align-self: flex-start;
  cursor: pointer;
}

.back-button {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.back-button:hover {
  text-decoration: underline;
}

.listing-description {
  color: #282828;
  text-align: left;
  font-weight: 200;
  font-size: 0.8;
  line-height: 1.3;
}

.listing-info {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.mobile-listing-info {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 6rem;
}

.web-listing-info {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-top: 7rem;
}

.booking-container {
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  height: 380px;
  margin: 20px;
  overflow: hidden;
}

.image-wrapper {
  width: 100%;
  height: 84%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}

.listing-cover {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.listing-cover-small {
  width: 150px; /* Set a fixed width for the image */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded corners */
  margin-right: 20px; /* Space between image and text */
}

.booking-info {
  padding: 10px;
  text-align: left;
}

.location {
  font-size: 0.8rem;
  color: #7e7e7e;
  margin: 0;
}

.tour-title {
  font-size: 1rem;
  color: #333;
  margin: 5px 0 0;
}

.event-header {
  display: flex;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

.event-header-info {
  margin-top: 5rem;
  flex-direction: column;
  justify-content: center;
}

.reservation-details {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Center items vertically */
  justify-content: flex-start;
  text-align: left; /* Align text to the left */
  flex-wrap: wrap;
  /* margin: 20px 0; Margin for spacing */
}

.reservation-info {
  /* margin-top: 20px; */
  display: flex;
  flex-direction: column; /* Stack title and location */
  align-items: flex-start;
  justify-content: flex-start;
}

.reservation-info h3 {
  font-size: 1.3rem;
  color: #000;
}

.reservation-info h6 {
  font-size: 0.8rem;
  color: #7e7e7e;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.card-container {
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.stepper-container {
  margin-bottom: 20px;
}

.step-item {
  font-size: 1rem;
  color: #333;
}

.content-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.content-box {
  width: 100%;
  height: 100%;
  border: 2px dashed #dcdcdc;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn-next {
  background-color: #000;
  color: #fff;
}

.btn-back {
  background-color: #e0e0e0;
  color: #000;
}

h1 {
  font-size: 3rem;
  color: #222;
  text-align: left;
  margin-bottom: 0.5rem;
}

h2 {
  font-size: 2rem;
  color: #222;
  text-align: left;
  margin-bottom: 0.5rem;
}

h3 {
  color: #222;
  margin-bottom: 0.5rem;
  text-align: left;
}

button.logout-button {
  border: none;
  border-radius: 50%;
  color: #333;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

button.logout-button:hover {
  background-color: #e5e7eb !important;
}

@media (max-width: 768px) {
  .web-header {
    padding: 1rem;
  }

  .main-content {
    padding: 1rem;
  }
}
</style>
