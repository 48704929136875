<template>
  <div class="app-container">
    <header class="web-header">
      <div class="header-left">
        <img src="@/assets/logo.png" alt="Skyhost Logo" class="logo" />
      </div>
      <nav class="header-right">
        <ul class="nav-links">
          <li>
            <button @click="logout" class="logout-button">
              <vue-feather
                style="width: 15px; height: 15px; color: black"
                type="log-out"
              ></vue-feather>
            </button>
          </li>
        </ul>
      </nav>
    </header>

    <!-- Main Content -->
    <main class="container">
      <div class="left-container">
        <div class="event-form">
          <h2>Create New Event</h2>
          <br />
          <!-- <h6 class="subheading">Tell us about your event</h6> -->
          <form @submit.prevent="handleSubmit">
            <div class="form-group">
              <label for="title">Title</label>
              <input
                type="text"
                id="title"
                v-model="event.title"
                placeholder="Enter title"
                required
              />
            </div>

            <div class="form-group">
              <label for="description">Description</label>
              <textarea
                id="description"
                v-model="event.description"
                placeholder="Provide description"
                required
              ></textarea>
            </div>

            <div class="form-group">
              <label for="location">Location</label>
              <!-- <input
                type="text"
                id="location"
                v-model="event.location"
                placeholder="Enter location"
                required
              /> -->
              <input
                ref="autocompleteInput"
                v-model="event.location"
                type="text"
                name="location"
                id="location"
                placeholder="Lake Shoji, Yamanashi, Japan"
                @keydown.enter.prevent
              />
            </div>

            <div class="form-group">
              <label for="date">Date</label>
              <input type="date" v-model="startDate" required />
            </div>

            <div class="form-group">
              <label for="time">Slots</label>
              <select
                id="time"
                v-model="selectedTimeSlot"
                @change="addTimeSlot"
              >
                <option disabled value="">Add slots</option>
                <option v-for="time in timeSlots" :key="time" :value="time">
                  {{ time.timeString }}
                </option>
              </select>
              <div class="selected-slots">
                <span
                  v-for="slot in selectedSlots"
                  :key="slot"
                  class="time-chip"
                >
                  {{ slot.timeString }}
                  <div @click="removeTimeSlot(slot)" class="remove-chip">
                    <vue-feather
                      type="x"
                      size="16"
                      style="color: black"
                    ></vue-feather>
                  </div>
                </span>
              </div>
            </div>

            <div class="form-group">
              <label>Repeat Event</label>
              <input type="checkbox" v-model="event.repeat" />
              <div v-if="event.repeat" class="repeat-options">
                <label for="frequency">Event Frequency</label>
                <select id="frequency" v-model="event.frequency">
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                </select>
                <label>Select Days of the Week</label>
                <div class="days">
                  <span v-for="day in daysOfWeek" :key="day">
                    <input
                      type="checkbox"
                      :id="day"
                      :value="day"
                      v-model="event.selectedDays"
                    />
                    <label :for="day">{{ day }}</label>
                  </span>
                </div>
                <div class="form-group">
                  <label for="date">End Date</label>
                  <input type="date" v-model="endDate" required />
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="banner">Add Banner</label>
              <input
                type="file"
                id="bannerUrl"
                @change="handleFileUpload"
                multiple
              />
            </div>

            <button type="submit">Save</button>
          </form>
        </div>
      </div>

      <div class="right-container">
        <div class="booking-container">
          <div style="width: 100%; margin-top: 10px">
            <VCalendar
              :attributes="attrs"
              title-position="left"
              borderless
              transparent
              :min-date="new Date()"
            />
          </div>
          <div class="booking-info" style="margin-top: -20px">
            <!-- <h4>Select Time</h4> -->
          </div>
          <div class="booking-info"></div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref } from "vue";
import { db } from "../plugins/firebase";
import { addDoc, collection } from "firebase/firestore";
import { storage } from "../plugins/firebase";
import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { useSessionStore } from "../stores/session";

export default {
  data() {
    return {
      event: {
        title: "",
        description: "",
        location: "",
        latitude: "",
        longitude: "",
        repeat: false,
        frequency: "",
        selectedDays: [],
        slotsByDate: new Map(),
        bannerUrl: null,
        bookingOptions: [
          {
            name: "Solo",
            value: "solo",
            price: 49,
            guests: 1,
            // productId: "prod_RKEWNycr9WiZUR", // test
            productId: "prod_RKXrffeLCR9yuy", // prod
          },
          {
            name: "Pair",
            value: "pair",
            price: 99,
            guests: 2,
            // productId: "prod_RKEWNycr9WiZUR", // test
            productId: "prod_RKFEC4PWYvInuw", // prod
          },
          {
            name: "Group",
            value: "group",
            price: 449,
            guests: 10,
            // productId: "prod_RKEacrs92852mD", // test
            productId: "prod_RKFDOZaIkekvbN", // prod
          },
        ],
      },
      startDate: "",
      endDate: "",
      selectedTimeSlot: "",
      selectedSlots: [],
      eligibleDays: [],
      timeSlots: this.generateTimeSlots(),
      daysOfWeek: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    };
  },
  methods: {
    generateSlotObject(timeSlot) {
      return {
        timeSlot,
        isAvailable: true,
        updatedAt: new Date(),
        isLocked: false,
      };
    },
    updateSlotsByDate() {
      this.eligibleDays.forEach((date) => {
        const dateString = date.toISOString().split("T")[0];
        const slotObjects = this.selectedSlots.map((slot) =>
          this.generateSlotObject(slot)
        );
        this.event.slotsByDate.set(dateString, slotObjects);
      });
    },
    generateTimeSlots() {
      const slots = [];
      let start = 0;
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's timezone
      // const userTimeZone = "Asia/Colombo";
      console.log("userTimeZone", userTimeZone);

      while (start < 24 * 60) {
        const hours = Math.floor(start / 60);
        const minutes = start % 60;

        // Create a Date object for the current time slot in the user's local timezone
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);

        // Convert the date to the user's local timezone
        const localTimeString = date.toLocaleString("en-US", {
          timeZone: userTimeZone,
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        // Push an object containing both the string and Date object
        slots.push({
          timeString: localTimeString, // Store the local time string
          date: date, // Store the Date object
          ampm: hours < 12 ? "AM" : "PM", // Add AM/PM information
        });

        start += 30; // Increment by 30 minutes
      }
      return slots;
    },
    addTimeSlot() {
      if (this.selectedTimeSlot) {
        if (!this.selectedSlots.includes(this.selectedTimeSlot)) {
          this.selectedSlots.push(this.selectedTimeSlot);
        }
        this.selectedTimeSlot = "";
      }
    },
    removeTimeSlot(slot) {
      this.selectedSlots = this.selectedSlots.filter((time) => time !== slot);
    },
    getSlotsForDate(date) {
      return this.event.slotsByDate.get(date) || [];
    },
    calculateEligibleDays() {
      const startDate = new Date(this.startDate);
      const endDate = new Date(this.endDate);
      const eligibleDays = [];

      if (this.event.repeat === false) {
        eligibleDays.push(startDate);
        this.eligibleDays = eligibleDays;
        return;
      }

      if (startDate > endDate) {
        console.error("Start date must be before end date.");
        return [];
      }

      const dayIndices = this.event.selectedDays.map((day) => {
        return this.daysOfWeek.indexOf(day);
      });

      for (
        let d = new Date(startDate);
        d <= endDate;
        d.setDate(d.getDate() + 1)
      ) {
        if (dayIndices.includes(d.getDay())) {
          eligibleDays.push(new Date(d));
        }
      }

      this.eligibleDays = eligibleDays;
      this.updateCalenderAttributes();
    },
    updateCalenderAttributes() {
      this.attrs = [
        {
          key: "today",
          dot: {
            color: "#42b983",
          },
          dates: new Date(),
        },
        {
          key: "eligible",
          highlight: {
            color: "#ff9800",
            fillMode: "light",
          },
          dates: this.eligibleDays,
        },
      ];
    },
    getUTCOffset() {
      const offset = new Date().getTimezoneOffset();
      const sign = offset <= 0 ? "+" : "-";
      const pad = (num) => String(num).padStart(2, "0");
      const hours = pad(Math.floor(Math.abs(offset) / 60));
      const minutes = pad(Math.abs(offset) % 60);
      return `UTC${sign}${hours}:${minutes}`;
    },
    convertToUTC12HourFormat(dateString) {
      const utcDate = new Date(dateString); // Convert the string to a Date object
      const hours = utcDate.getUTCHours(); // Get UTC hours
      const minutes = utcDate.getUTCMinutes(); // Get UTC minutes

      const period = hours >= 12 ? "PM" : "AM";
      const hour12 = hours % 12 || 12; // Convert to 12-hour format

      return `${hour12.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")} ${period}`;
    },

    // Convert dates and times to the desired format
    async convertData(data) {
      const updatedData = {};

      for (const key in data) {
        updatedData[key] = data[key].map((item) => ({
          ...item,
          timeSlot: {
            ...item.timeSlot,
            date: new Date(item.timeSlot.date).toISOString(), // Convert date to UTC format
            timeString: this.convertToUTC12HourFormat(item.timeSlot.date), // Convert time to 12-hour UTC format
          },
        }));
      }

      return updatedData;
    },

    async handleSubmit() {
      const timezone = this.getUTCOffset();
      const sessionStore = useSessionStore();

      this.updateSlotsByDate();
      // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const slotsByDateObject = Object.fromEntries(this.event.slotsByDate);
      console.log("slotsByDateObject", slotsByDateObject);
      let utcSlotsByDate = await this.convertData(slotsByDateObject);
      console.log("utcSlotsByDate", utcSlotsByDate);

      const eventData = {
        ...this.event,
        creatorUserId: sessionStore.userId,
        startDate: this.startDate,
        endDate: this.event.repeat ? this.endDate : this.startDate,
        slotsByDate: utcSlotsByDate, // Convert Map to object
        eligibleDays: this.eligibleDays,
        createdAt: new Date(),
        timezone: timezone,
      };

      console.log("Event data:", eventData);
      await addDoc(collection(db, "listings"), eventData);
      this.$router.push({ name: "Listings" });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      const metadata = {
        contentType: "image/jpeg",
      };
      const storageRef_ = storageRef(storage, "images/" + file.name);
      uploadBytesResumable(storageRef_, file, metadata).then(
        async (snapshot) => {
          const url = await getDownloadURL(snapshot.ref);
          this.event.bannerUrl = url;
        }
      );
    },
    clearSelection() {
      console.log("Clearing selection...");
      this.event.frequency = "";
      this.event.selectedDays = [];
      this.event.slotsByDate = new Map();
      this.endDate = "";
      this.calculateEligibleDays();
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("location")
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        this.event.latitude = place.geometry.location.lat();
        this.event.longitude = place.geometry.location.lng();
      }
      this.event.location = place.formatted_address;
    });
  },
  watch: {
    startDate() {
      this.calculateEligibleDays();
    },
    endDate() {
      this.calculateEligibleDays();
    },
    "event.repeats": {
      handler() {
        this.clearSelection();
      },
      deep: true,
    },
    "event.selectedDays": {
      handler() {
        this.calculateEligibleDays();
      },
      deep: true,
    },
  },
  setup() {
    const attrs = ref([
      {
        key: "today",
        dot: {
          color: "#42b983",
        },
        dates: new Date(),
      },
    ]);
    return {
      attrs,
    };
  },
};
</script>

<style scoped>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
  color: #333;
}

.web-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
}

.logo {
  height: 25px;
  width: 25px;
  margin-right: 1rem;
}

.header-center {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.header-right {
  display: flex;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-links a,
.nav-links button {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;
}

.nav-links a:hover,
.nav-links button:hover {
  text-decoration: underline;
}

.logout-button {
  background-color: #e5e7eb !important;
  border: none;
  border-radius: 12px;
  color: white;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.logout-button:hover {
  background-color: rgb(235, 235, 235) !important;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  overflow: hidden;
}

.left-container {
  flex: 1.5;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

.left-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

.right-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.calendar-section,
.booking-container {
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  height: auto;
  flex: 1;
  max-width: 17rem;
  margin: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333;
}

.booking-info {
  width: 100%;
  text-align: left;
  margin-left: 20px;
  margin-top: 20px;
}

.event-form {
  text-align: left;
  padding: 20px;
  width: 75%;
}

.form-group {
  margin-bottom: 15px;
}

.selected-slots {
  margin-top: 10px;
}

.time-chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 5px 10px;
  margin: 5px;
  font-size: 0.8rem;
}

.remove-chip {
  border: none;
  color: white;
  cursor: pointer;
  margin-left: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0;
}

.remove-chip:hover {
  background-color: #7e7e7e;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: black;
}

input[type="text"],
input[type="date"],
input[type="time"],
textarea,
select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.days span {
  display: inline-block;
  margin-right: 10px;
}

.subheading {
  color: #7e7e7e;
  text-align: left;
  font-weight: 400;
  font-size: 0.8;
}

h2 {
  font-size: 2rem;
  color: #222;
  text-align: left;
  margin-bottom: 0.5rem;
}
</style>
