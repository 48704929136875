<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <header :class="headerClass">
    <div class="header-left">
      <button @click="navigateToHome" class="logo-button">
        <img
          :src="logoSrc"
          alt="Skyhost Logo"
          class="logo"
          :style="{ transform: `rotate(${logoRotation}deg)` }"
          :class="{ rotating: true }"
        />
      </button>

      <h2 :class="titleClass">S K Y H O S T</h2>
    </div>
    <div class="header-center" v-if="showHeaderCenter">
      <button @click="toggleView" class="toggle-button">
        <vue-feather v-if="isMapView" type="grid" size="18"></vue-feather>
        <vue-feather v-else type="globe" size="18"></vue-feather>
      </button>
    </div>
    <nav class="header-right" v-show="!hideDropdown">
      <ul class="nav-links">
        <!-- <li v-if="!isUserAuthenticated">
          <p :class="headerLinksClass">Become a Pilot</p>
        </li> -->
        <li v-if="isUserAuthenticated">
          <button @click="toggleDropdown" class="dropdown-button">
            <div class="dropdown-button-content">
              <vue-feather
                type="menu"
                style="color: black; margin-left: 10px"
                size="12"
              ></vue-feather>
              <div class="signin-user-icon">
                {{ userFirstLetter }}
              </div>
            </div>
          </button>
          <div v-if="isDropdownOpen" class="dropdown-menu">
            <ul>
              <li @click="handleHomeSelection">Home</li>
              <li @click="handleBookingSelection">Bookings</li>
              <!-- <li>Account</li> -->
              <div v-if="isUserPilot">
                <hr />
                <li @click="handleListingSelection">Listings</li>
                <li @click="handleReservationSelection">Reservations</li>
              </div>
              <!-- <div v-else>
                <hr />
                <li>Become a pilot</li>
                <li>How it Works</li>
                <li>Help</li>
              </div> -->
              <hr />
              <li @click="logout">Log out</li>
            </ul>
          </div>
        </li>
        <li v-else>
          <button @click="toggleDropdown" class="dropdown-button">
            <div class="dropdown-button-content">
              <vue-feather
                type="menu"
                style="color: black; margin-left: 10px"
                size="12"
              ></vue-feather>
              <div class="user-icon"></div>
            </div>
          </button>
          <div v-if="isDropdownOpen" class="dropdown-menu">
            <ul>
              <li @click="handleSignUpSelection">Sign up</li>
              <li @click="handleLoginSelection">Log in</li>
              <!-- <hr />
              <li>Become a pilot</li>
              <li>How it Works</li>
              <li>Help</li> -->
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { useSessionStore } from "@/stores/session";
import { signOut } from "firebase/auth";
import { auth } from "@/plugins/firebase";
import { computed } from "vue";
import { isMobile } from "@/utils/helper";

export default {
  props: {
    isMapView: Boolean,
    showHeaderCenter: Boolean,
    enableDarkMode: Boolean,
    logoRotation: Number,
    hideDropdown: Boolean,
  },
  data() {
    return {
      isDropdownOpen: false,
      user: null,
      userFirstLetter: "",
    };
  },
  computed: {
    isUserAuthenticated() {
      const sessionStore = useSessionStore();
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.userFirstLetter = sessionStore.user?.name.charAt(0).toUpperCase();
      return sessionStore.userId !== null;
    },
    isUserPilot() {
      const sessionStore = useSessionStore();
      return sessionStore.user?.isPilot;
    },
  },
  mounted() {
    const sessionStore = useSessionStore();
    this.user = sessionStore.user;
  },
  setup(props) {
    const headerClass = computed(() => {
      // Determine the base class based on mobile or web
      const baseClass = isMobile() ? "mobile-header" : "web-header";
      // Add dark mode class if enabled
      return props.enableDarkMode === true
        ? `${baseClass} dark-mode`
        : baseClass;
    });

    const headerLinksClass = computed(() => {
      return props.enableDarkMode === true ? "pilot-link-light" : "pilot-link";
    });

    const titleClass = computed(() => {
      return props.enableDarkMode === true ? "title-dark" : "title-light";
    });

    const logoSrc = computed(() => {
      return props.enableDarkMode === true
        ? require("@/assets/logo_white.png")
        : require("@/assets/logo.png");
    });

    return {
      headerLinksClass,
      headerClass,
      titleClass,
      logoSrc,
    };
  },
  watch: {
    user() {
      this.userFirstLetter = this.user?.name.charAt(0).toUpperCase();
    },
  },
  methods: {
    toggleView() {
      this.$emit("toggleView");
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    navigateToHome() {
      this.$router.push("/");
    },
    handleHomeSelection() {
      this.toggleDropdown();
      this.navigateToHome();
    },
    handleLoginSelection() {
      this.toggleDropdown();
      this.$emit("handleLoginSelection");
    },
    handleSignUpSelection() {
      this.toggleDropdown();
      this.$emit("handleSignUpSelection");
    },
    handleBookingSelection() {
      this.toggleDropdown();
      this.$router.push("/bookings");
    },
    handleListingSelection() {
      this.toggleDropdown();
      this.$router.push("/listings");
    },
    handleReservationSelection() {
      this.toggleDropdown();
      this.$router.push("/dashboard");
    },
    handleFlightLogsSelection() {
      this.toggleDropdown();
      this.$emit("handleFlightLogsSelection");
    },
    logout() {
      this.toggleDropdown();
      signOut(auth)
        .then(() => {
          const sessionStore = useSessionStore();
          sessionStore.clearUser();
          this.$router.push("/");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
  },
};
</script>

<style scoped>
.web-header {
  position: fixed; /* Fix the header at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5rem;
  min-height: 5rem;
  background-color: #fff;
  border-bottom: 1px solid #f2f5f9;
}

.web-header:not(.dark-mode) {
  background-color: #fff; /* Light mode background */
}

.web-header.dark-mode {
  background-color: #000;
  border-bottom: 1px solid #000;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  min-height: 5rem;
  background-color: #fff;
  border-bottom: 1px solid #f2f5f9;
}

.web-header:not(.dark-mode) {
  background-color: #fff; /* Light mode background */
}

.mobile-header.dark-mode {
  background-color: #000;
  border-bottom: 1px solid #000;
}

.header-left {
  display: flex;
  flex: 1;
  align-items: center;
}

.title-dark {
  color: white;
  font-weight: 100;
  font-size: 1.2rem;
}

.title-light {
  color: black;
  font-weight: 100;
  font-size: 1.2rem;
}

.header-center {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
}

.logo-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.toggle-button {
  border: none;
  border-radius: 50%;
  background-color: #fff;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.toggle-button:hover {
  background-color: #f2f5f9 !important;
}

.logo {
  margin-top: 3px;
  height: 25px;
  margin-right: 1rem;
  transition: transform 1s ease-out;
}

.pilot-link {
  color: black;
  font-weight: 600;
  font-size: 12px;
}

.pilot-link-light {
  color: white;
  font-weight: 600;
  font-size: 12px;
}

.header-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.logout-button {
  background-color: #f2f5f9 !important;
  border: none;
  border-radius: 12px;
  color: white;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.dropdown-button {
  background-color: #fff;
  display: flex;
  align-items: center;
  width: 80px;
  height: 50px;
  border: 1px solid #f2f5f9 !important;
  border-radius: 25px;
  cursor: pointer;
  padding: 5px;
  outline: none;
  transition: background-color 0.3s ease;
}

.dropdown-button:hover {
  background-color: #f2f5f9;
}

.dropdown-button-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #f2f5f9;
  margin-right: 5px;
  position: relative;
}

.signin-user-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f2f5f9;
  margin-right: 5px;
  position: relative;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-menu {
  position: absolute;
  top: 5rem;
  right: 5rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  width: 250px;
  z-index: 100;
  padding: 10px;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu ul li {
  color: #000;
  font-size: 14px;
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu ul li:hover {
  background-color: #f2f5f9;
}

.dropdown-menu hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #eee;
}
</style>
