import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createPinia } from "pinia";
import "./registerServiceWorker";
import { initializeAmplitude } from "./plugins/amplitude";
import { firebaseApp } from "./plugins/firebase";
import VueFeather from "vue-feather";
import VCalendar from "v-calendar";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import "v-calendar/style.css";

initializeAmplitude();
firebaseApp;

const pinia = createPinia();
const app = createApp(App);
app.use(router);
app.use(pinia);
app.use(Toast);
app.component(VueFeather.name, VueFeather);
app.use(VCalendar, {});
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false || "none",
    },
  },
});
app.mount("#app");
