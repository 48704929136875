import { auth, db } from "@/plugins/firebase";
import { collection, addDoc } from "firebase/firestore";
import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { isValidEmail } from "@/utils/helper";

export const userAuth = {
  async signUpUser(name, email, password, confirmPassword) {
    if (!name || !email || !password || !confirmPassword) {
      throw new Error("Please fill in all the fields");
    }

    if (!isValidEmail(email)) {
      throw new Error("Please enter a valid email address");
    }

    if (password !== confirmPassword) {
      throw new Error("Passwords do not match");
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const userData = {
        userId: user.uid,
        name: name,
        email: email,
        credit: 100,
        createdAt: new Date(),
      };

      const docRef = await addDoc(collection(db, "users"), userData);
      console.log("Document written with ID: ", docRef.id);

      return user;
    } catch (error) {
      handleSignUpError(error);
    }
  },
};

function handleSignUpError(error) {
  const errorCode = error.code;
  if (errorCode === "auth/email-already-in-use") {
    throw new Error("Email already in use");
  } else if (errorCode === "auth/invalid-email") {
    throw new Error("Invalid email");
  } else if (errorCode === "auth/operation-not-allowed") {
    throw new Error("Operation not allowed");
  } else if (errorCode === "auth/weak-password") {
    throw new Error("Weak password");
  } else {
    throw new Error("Unknown error occurred");
  }
}

export const loginService = {
  async loginUser(email, password) {
    if (!email || !password) {
      throw new Error("Please fill in all the fields");
    }

    if (!isValidEmail(email)) {
      throw new Error("Please enter a valid email address");
    }

    try {
      // Set the persistence so the session remains
      await setPersistence(auth, browserLocalPersistence);

      // Perform the sign-in
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential.user;
    } catch (error) {
      return loginService.handleLoginError(error);
    }
  },

  handleLoginError(error) {
    const errorCode = error.code;

    switch (errorCode) {
      case "auth/invalid-email":
        throw new Error("Invalid email");
      case "auth/user-disabled":
        throw new Error("User disabled");
      case "auth/user-not-found":
        throw new Error("User not found");
      case "auth/wrong-password":
        throw new Error("Wrong password");
      case "auth/too-many-requests":
        throw new Error("Too many requests. Please try again later.");
      default:
        throw new Error("An unknown error occurred.");
    }
  },
};
