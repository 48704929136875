<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container">
    <div class="right-side">
      <div ref="mapContainer" class="map-container"></div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import mapboxgl from "mapbox-gl";
import { toRefs } from "vue";

export default {
  props: {
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { latitude, longitude } = toRefs(props);
    const mapContainer = ref(null); // Define mapContainer as a ref
    const initializeMap = () => {
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN;

      new mapboxgl.Map({
        container: mapContainer.value, // Access mapContainer using its value
        style: process.env.VUE_APP_MAPBOX_STYLE_URL,
        center: [longitude.value, latitude.value],
        zoom: 12,
        pitch: 0,
        minZoom: 6,
        maxZoom: 14,
        dragPan: false,
        touchZoomRotate: false,
        scrollZoom: false,
      });
    };

    // Use the mounted lifecycle hook to initialize the map
    onMounted(() => {
      initializeMap();
    });

    return {
      mapContainer, // Return mapContainer to the template
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  height: 250px;
}

.right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.map-container {
  width: 100%;
  height: 100%;
}
</style>
