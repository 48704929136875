<template>
  <div>
    <!-- Other content -->
    <NewFlight />
    <!-- Other content -->
  </div>
</template>

<script>
import NewFlight from "@/views/NewFlight.vue";

export default {
  components: {
    NewFlight,
  },
  // other options...
};
</script>
